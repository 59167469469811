import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import {BACKURL} from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';

const ModalAddProduct = ({ }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { setModalInfo, modalAddProduct, admin,servOrders, setServOrders, serverProd, sclad, setSclad, infomodal, setModalAddProduct, setModalEditProduct } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({})
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12464" && setModalAddProduct(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  // let render = []
  // for (let i = 0; i < sclad.length; i++) {
  //   let t = 0
  //   for (let i2 = 0; i2 < infomodal.data.length; i2++) {
  //     let item = JSON.parse(infomodal.data[i2])
  //     if (sclad[i].name == item.name) {
  //       // console.log(item.name, sclad[i].name)
  //       t++
  //     }
  //   }
  //   if (t == 0) {
  //     render.push(sclad[i])
  //   }

  // }
  // infomodal.data
  const [err, setErr] = useState({
    name: false,
    qty: false
  })

  const editPrice = async () => {
try{


    let t = {
      name: false,
      qty: false
    }
    const test = sclad.find(prod => prod.name == newValue.name)
    if(test){
        if (newValue.qty > test.scladqty) {
      t.qty = `На складе всего ${test.scladqty} шт!`
    }
    }
  
    if (!newValue.qty) {
      t.qty = `Введите число!`

    }
    if (!newValue.name) {
      t.name = `Выберите продукт!`

    }

    // if (!Number.isInteger(newValue)) {
    //   t = `Введите число!`

    // }

    setErr(t)
    if (
      !t.name &&
      !t.qty &&
      newValue.name,
      newValue.qty
    ) {
      seloading(true)
      console.log(newValue)
      const newProd = await $api.post(`${BACKURL}/api/utbteam/addorderproduct/${infomodal.order_id}`,
        { newValue: newValue,admin:admin.user }
      )
      const newPosts = await servOrders.map((post) => (
        post.id == infomodal.order_id
          ? newProd.data
          : post
      ));
      setServOrders(newPosts)
      // setSclad(newPosts)

      // //Проверь правильность данных тут
      // setServerProd(newPosts)
      // console.log(newProd.data)
      setModalInfo(false)
      seloading(false)

      // localStorage.setItem("petNew", JSON.stringify(newPosts));
      setModalAddProduct(false)
      alert('Кол-во успешно обновлено в заказе!')
    }

} catch(e){
  seloading(false)

}


  }



  return (
    <>
      {modalAddProduct && (
        <div onClick={closeModal} id="container12464" className={style.modal_container}>

          <div className={style.modal}>
            <div>
              <Title text={`Добавление продукта в заказ`}></Title>
            </div>
            <form className={style.form}>
              <label for="prod">Выберите Товар:</label>

              <select name="pets" id="prod"
                onChange={(event) => {
                  setNeValue({ ...newValue, name: event.target.value });
                }}
              >
                <option value="">--Выберите Продукт--</option>

                {sclad.map(
                  (prod, index) => {
                  // const test = sclad.find(prod1 => prod1.name == prod.name)

                  return <>

                    <option value={prod.id}>{prod.name} - Доступно {prod.scladqty} шт</option>
                  </>}
                )}
              </select>
              {err.name && (
                <p style={{ color: "red" }}>{err.name}</p>
              )}
              <label for="кол-во">Введите кол-во:</label>
              <input type="number" id="кол-во" name="кол-во" onChange={(event) => {
                setNeValue({ ...newValue, qty: event.target.value })
              }} />
              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </form>
            <Button
              text="Добавить Продукт"
              background="var(--primary-color)"
              color="white"
              width="100%"
              onClick={editPrice}
            />
          </div>

        </div>

        // </div>
      )}
 {loading&&(
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
    </>
  );
};

export default ModalAddProduct;
