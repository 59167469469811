import React from 'react'
import Button from '../../Button/Button'
import style from './Admin.module.css'
import Title from "../../Title";
import axios from 'axios';
import { QtdContext } from "../../../context/qtdContext";
import { useEffect, useState, useContext } from "react";
import $api from "../../../http/index.ts";
import Dashboard from '../DashBoard/Dashboard';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
const Admin = () => {
    const { admin, setAdmin } = useContext(QtdContext);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, [])

    const [logdata, setLogData] = useState({
        login: '',
        pass: ''
    })
    const LogIn = async () => {
        try {
            setLoading(true)
            const response = await $api.post(`${BACKURL}/api/utbteam/login`, logdata)
            // console.log(response.data.user.login)
            // const log = response.data.user.login
            // setAdmin(JSON.parse(user.data.user))
            localStorage.setItem('token', response.data.accessToken);
            // localStorage.setItem('admin', response.data.user.login);
            // const log = localStorage.getItem('admin')
            
            setLoading(false)

            setAdmin({ auth: true, user: response.data.user.login,role: response.data.user.role });

            // setAdmin(log);
            // setAuth(true)

        } catch (e) {
            setLoading(false)
            // console.log(e)
        }



    }
    return (
        <>
            {!admin.auth && (<>
                <Title text="Вход" />

                <div className={style.container}>
                    <div className={style.search}>

                        <form className={style.form}>
                            <label for="n"><h4>Введите логин!</h4></label>
                            <input type="text" id="n" name="n" onChange={(event) => {
                                setLogData({ ...logdata, login: event.target.value });
                            }} />
                            <label for="tel"><h4>Введите пароль!</h4></label>
                            <input type="password" id="tel" name="tel" onChange={(event) => {
                                setLogData({ ...logdata, pass: event.target.value });
                            }} />

                        </form>
                        {!loading && (
                            <Button text='Войти' onClick={LogIn} />

                        )}
                        {loading && (
                            <Button text='Входим в систему...' disabled={true} />

                        )}

                    </div>


                </div>
            </>
            )}
            {admin.auth && (<>
                <Title text="Панель Управления" />
                <Dashboard></Dashboard>
            </>
            )}
            {loading&&(
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
        </>
    )
}

export default Admin