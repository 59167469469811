import React from 'react'
// import Button from '../../../../Button/Button'
import style from './ClicksStats.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../context/qtdContext";
import $api from "../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../../Modal/Modal';
import Button from '../../../../../Button/Button';
import { FcInfo, FcCancel } from "react-icons/fc";
import { confirmAlert } from 'react-confirm-alert'; // Import
import ClicksService from '../../../../../../services/clicks';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const ClicksStats = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );


    const { clicks, setClicks, admin, sclad } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);



    // const servOrders = JSON.parse(localStorage.getItem("servOrders"));
    const start = clicks.sort((a, b) => a.counter > b.counter ? 1 : -1);
    const labels = ["Товары"];
    const types = sclad.map(({ name }) => name)
    let products = []

    for (let i = 0; i < start.length; i++) {
                    // console.log(clicks[i].name)

        if (types.includes(start[i].name)) {
            products.push(start[i])
            // labels.push(clicks[i].name)
        }
    }
    // const products = clicks.filter(click => click.name != 'Запуск Сайта')

    // console.log( products,types, clicks)
    // useEffect(() => {
    //     // const Orders = await $api.get()

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Все клики',
            },
        },
    };


    const data1 = {
        labels,
        datasets: products.map((clk) => ({

            label: clk.name,
            data: [clk.counter],
            backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
        })

        )

    };

    const deletit = async (data) => {
        await ClicksService.deleteclick(data)
        const Clic = clicks.filter((post) => (
            post.id != data
        ));

        // setSclad(newPosts)
        setClicks(Clic)

        alert(`Клик id ${data} удален!`)
    }
    const submitDeket = async (data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены в том, что хотите удалить клик?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => deletit(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    // const [thisChange, setThisChange] = useState({})


    return (<>
        <br />
        <table className={style.table}>

            <thead className={style.tableth}>

                <tr>

                    <td className={style.tabletd}>/</td>
                    <td className={style.tabletd}>Всего</td>
                    <td className={style.tabletd}>Новых Сегодня</td>
                    {admin.role == 'GOD' && (
                        <td className={style.tabletd}>Удалить</td>

                    )}
                </tr>
            </thead>
            <tbody>
                {products.map(pr =>
                    <tr>

                        <td className={style.tabletd}>{pr.name}</td>
                        <td className={style.tabletd}>{pr.counter}</td>
                        <td className={style.tabletd}>{pr.countertoday}</td>

                        {admin.role == 'GOD' && (
                            <td className={style.tabletd}><FcCancel onClick={() => submitDeket(pr.id)} /></td>

                        )}
                    </tr>)}



            </tbody>
        </table>
        <br />
        <Bar options={options} data={data1} />
        {/* {modalEdit && (
            <ModalEdit
                thisChange={thisChange}
                setThisChange={setThisChange}
            // prod = {prod}
            />

        )} */}
    </>
    )
}
export default ClicksStats