import React, { useContext, useEffect, useState, Fragment } from "react";
import style from "./ProdInfo.module.css";
import Title from "../../Title";
import Button from "../../Button/Button";
import ButtonsModal from "../../Modal/ButtonsModal/ButtonsModal";
import { QtdContext } from "../../../context/qtdContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { FcUndo } from "react-icons/fc";
import ClicksService from "../../../services/clicks";
import FeedBack from "../FeedBack/FeedBack";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ProdInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let imageId1 = useParams()
  // console.log(imageId1.imageid)
  const imageId = Number(imageId1.imageid)
  // const serverProd = location.state.serverProd;
  // console.log('prodinfi', imageId)


  const go = async (text) => {
    switch (true) {
      case (text == 'Консервы'):
        navigate('/cuns')
        break
      case (text == 'Лакомства'):
        navigate('/lacomstva')
        break
      case (text == 'Рационы'):
        navigate('/racions')
        break
    }
  }
  const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    borderRadius: '30px',
    backgroundRepeat: 'no-repeat',
    // height: '500px',
    width: 'auto',
    objectFit: 'contain'

  }


  const { serverProd, produtosSelecionados, opt, setModalCart, admin, setProdutosSelecionados } = useContext(QtdContext);
  // const produtosSelecionados = location.state.produtosSelecionados;
  // const name1 = location.state.name;


  // useEffect(() => {
  // // 
  // const test = async () => {
  //   if (!admin.auth) {


  //   }
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  // }
  // test()



  // }, [])

  // get userId
  //  const navigate = useNavigate()
  // console.log(imageId)
  const produtos = serverProd

  const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +imageId)
  const produtoDados = produtos.find((produto) => produto.id == +imageId);



  const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
  // console.log('====================', produtoFoiSelecionado, produtos)
  // console.log('prodinfi', imageId, produtosSelecionados, produtoDados)

  const { id, name,
    src, scladqty,
    price, optprice, text,
    photo, recomendation, vipprice, mnt
    // , desc 
  } = produto;

  ClicksService.addone(name)
  let abzats = [text]
  let abzatsrecomendation = [recomendation]

  if (text) {
    abzats = text.split('{}')

  }
  if (recomendation) {
    abzatsrecomendation = recomendation.split('{}')
  }




  const changeItensCart = (id) => {
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +imageId);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  let imgs = ''
  try {
    imgs = require(`../../Assets/tiny/${name}.png`)

  } catch (e) {
    imgs = ''
    // next(e)
  }
  const cart = produtosSelecionados.find((produto) => produto.id == +imageId);
  let cartb = false
  if (cart) {
    cartb = true
  }


  return (
    <div id="container" className={style.modal_container}>
      <div className={style.back} onClick={() => navigate(-1)}>
        <FcUndo className={style.icon} onClick={() => navigate(-1)} /> НАЗАД
      </div>
      <div >
        <script src="https://kit.fontawesome.com/0ef5f418de.js" crossorigin="anonymous"></script>
        <div className={style.title} >
          <Title text={name} />
        </div>
        <div className={style.container_infos}>

          {photo && (
            // <div className={style.imgdiv}>
            <div className={style.slidecontainermain} >
              <div className="slide-container"
                style={{ width: '60%' }}
              >
                <Slide>
                  {photo.map((slideImage, index) => (
                    <div key={index} className={style.scale}>
                      {/* <img src={slideImage} alt='котопес калиниград' /> */}
                      <LazyLoadImage
                        id={id}
                        style={divStyle}
                        // className={style.img}
                        src={slideImage}
                        alt="котопес калиниград"
                        // height="300px"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                      {/* <span style={spanStyle}>{index+1}</span> */}

                    </div>
                  ))}
                </Slide>
              </div>

            </div>
          )}


          <div className={style.conten}
            style={{
              'display': 'flex',
              'width': '100%',

              'flex-direction': 'column', 'align-items': 'center',
            }}
          >
            <br></br>
            <div className={style.price}>
              <br></br>
              <span>Цена:</span>
              {!opt.auth &&
                <p className={style.p}><b>{price}RUB</b></p>
              }
              {opt.auth && (<>
                {
                  vipprice.find(vp => vp.optuserid == opt.user.id).vipprice > 0 ? (
                    <div href="#" className={style.price} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      <span> {vipprice.find(vp => vp.optuserid == opt.user.id).vipprice}RUB
                      </span>
                    </div>
                  ) :

                    (<div href="#" className={style.price} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      <span>  {optprice}RUB
                      </span>
                    </div>)
                }</>
              )}
              <p className={style.p}>Наличие:
                {scladqty > 0 && <>
                  <b>{scladqty}{mnt}</b>
                </>}
                {scladqty <= 0 &&
                  <b>Товара нет в наличии!</b>
                }
              </p>

              {scladqty > 0 && <>
                {!cartb && (
                  <div className={style.btns} id={id}>

                    <div className={style.container_price}>

                      <ButtonsModal id={id} />
                    </div>
                    <Button text="Добавить в Корзину" onClick={changeItensCart} />

                  </div>
                )}
                {cartb && (
                  <div className={style.btns} id={id}>
                    {/* <div className={style.container_price}>



                  <ButtonsModal id={id} />
                </div> */}
                    <div className={style.btnsback}>
                      <Button text="Перейти в Корзину" style={{ 'width': '130px' }} onClick={() => setModalCart(true)} />



                      <Button text="Лакомства" style={{ 'width': '130px' }} background='#20b6e8' onClick={() => go("Лакомства")} />
                    </div>
                    <div className={style.btnsback}>
                      <Button text="Консервы" style={{ 'width': '130px' }} background='#ff6781' onClick={() => go("Консервы")} />

                      <Button text="Рационы" background='#04b31b' style={{ 'width': '130px' }} onClick={() => go("Рационы")} />


                    </div>
                  </div>
                )}
              </>
              }






            </div>
            <br></br>
            {/* <div className={style.descricao}> */}
            <Tabs style={{
              'display': 'flex',
              'flex-direction': 'column', 'align-items': 'center', 'width': '100%'
            }}>
              <TabList style={{
                'width': '80%'
              }}>
                <Tab>Описание</Tab>
                <Tab>Рекомендации</Tab>
                {/* <Tab>Отзывы</Tab> */}
              </TabList>

              <TabPanel style={{
                'display': 'flex',
                'flex-direction': 'column', 'width': '80%'
              }}>
                {abzats.map(abz => (<>
                  <p key={abz}>{abz}</p>

                </>
                ))}                </TabPanel>

              <TabPanel style={{
                'display': 'flex',
                'flex-direction': 'column', 'width': '80%'
              }}>
                {abzatsrecomendation.map(abz => (<>
                  <p key={abz}>{abz}</p>

                </>
                ))}
              </TabPanel>


              <TabPanel style={{
                'display': 'flex',
                'flex-direction': 'column', 'width': '80%'
              }}>
                {/* <FeedBack /> */}
              </TabPanel>
            </Tabs>


            <br></br>

            {/* <div className={style.descricao}>
            {desc.map((desc) => (
              <p key={desc}>- {desc}</p>
            ))}
            
          </div> */}


          </div>





          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProdInfo;
