import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';

const ModalAddSpec = ({ id }) => {
  const [loading, seloading] = useState(false)


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { setModalInfoSpec, setModalAddSpec, spec, modalAddSpec, setSpec, setModalInfo, modalAddProduct, admin, servOrders, setServOrders, serverProd, sclad, setSclad, infomodal, setModalAddProduct, setModalEditProduct } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({})
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12462" && setModalAddSpec(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const resourses = sclad.filter((post) => post.kategory.includes('Производство'))

  // infomodal.data
  const [err, setErr] = useState({
    name: false,
    qty: false
  })

  const editPrice = async () => {



try{
 let t = {
      name: false,
      qty: false
    }
    const test = sclad.find(prod => prod.name == newValue.name)
    // if(test){
    //     if (newValue.qty > test.scladqty) {
    //   t.qty = `На складе всего ${test.scladqty} шт!`
    // }
    // }

    if (!newValue.qty) {
      t.qty = `Введите число!`

    }
    if (!newValue.name) {
      t.name = `Выберите продукт!`

    }

    // if (!Number.isInteger(newValue)) {
    //   t = `Введите число!`

    // }

    setErr(t)
    if (
      !t.name &&
      !t.qty &&
      newValue.name,
      newValue.qty
    ) {
      seloading(true)

      // console.log(newValue, infomodal.order_id)
      const newProd = await $api.post(`${BACKURL}/api/utbteam/addspecroduct/${id}`,
        { newValue: newValue, admin: admin.user }
      )
      const newPosts = await spec.map((post) => (
        post.id == id
          ? newProd.data
          : post
      ));
      setSpec(newPosts)
      // setSclad(newPosts)

      // //Проверь правильность данных тут
      // setServerProd(newPosts)
      // console.log(newProd.data)
      setModalInfoSpec(false)
      // localStorage.setItem("petNew", JSON.stringify(newPosts));
      setModalAddSpec(false)
      seloading(false)

      alert('успешно довавлено в спецификацию!')
    }
} catch(e){
  seloading(false)
}
   

  }



  return (
    <>
      {modalAddSpec && (
        <div onClick={closeModal} id="container12462" className={style.modal_container}>

          <div className={style.modal}>
            <div>
              <Title text={`Добавление ресурса в спецификацию`}></Title>
            </div>
            <form className={style.form}>
              <label for="prod">Выберите Товар:</label>

              <select name="pets" id="prod"
                onChange={(event) => {
                  setNeValue({ ...newValue, name: event.target.value });
                }}
              >
                <option value="">--Выберите Продукт--</option>

                {resourses.map((ress, index) =>
                  <option value={ress.name}>{ress.name}(На складе:{ress.scladqty}{ress.mnt})</option>

                )}

              </select>
              {err.name && (
                <p style={{ color: "red" }}>{err.name}</p>
              )}
              <br></br>
              <label for="кол-во">Введите кол-во:</label>
              <input type="number" id="кол-во" name="кол-во" onChange={(event) => {
                setNeValue({ ...newValue, qty: event.target.value })
              }} />
              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </form>
            <Button
              text="Добавить Продукт"
              background="var(--primary-color)"
              color="white"
              width="100%"
              onClick={editPrice}
            />
          </div>

        </div>

        // </div>
      )}
 {loading&&(
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
    </>
  );
};

export default ModalAddSpec;
