import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewProdForm.module.css'
// import Title from "../../Title";
import Button from '../../../../../../Button/Button'
import $api from "../../../../../../../http/index.ts";

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
import { FcCancel } from "react-icons/fc";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../../../conf.js'
import Loading from 'react-fullscreen-loading';

const Prihod = () => {
    const { optUsers, scladHistory, setServerProd, setScladHistory, sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    const [newProd, setNewProd] = useState({
        from: "",
        kategory: 'Приход',
        type: '',
        comment: '',
    })
    const [prodArr, setProdArr] = useState([])
    const deleteFromList = (prod_id) => {
        // e.preventDefault();
        const newList = prodArr.filter(pr => pr.prod_id != prod_id)
        setProdArr(newList)
    }
    const [prodOne, setOneProd] = useState({
        prod_id: "",
        name: "",
        kategory: '',
        qty: '',
        newqty: '',
        shcode: '',
        mnt: ''

    })
    const [err2, setErr2] = useState({})

    const addOne = async (e) => {
        try {
            e.preventDefault();
            console.log(prodOne)
            let t1 = {
                prod_id: false,
                name: false,
                qty: false,
                kategory: false,
                shcode: false,
                mnt: false,
            }
            if (!prodOne.name) {
                t1.name = 'Выберите товар для оприходывания'
            }
            if (!prodOne.qty) {
                t1.qty = 'Выберите количество'
            }
            setErr2(t1)
            if (
                prodOne.prod_id &&
                prodOne.name &&
                prodOne.qty &&
                prodOne.kategory &&
                prodOne.shcode &&
                prodOne.mnt &&
                !t1.name &&
                !t1.qty

            ) {
                setProdArr([...prodArr, prodOne])
                setOneProd({
                    prod_id: '',
                    name: "",
                    kategory: '',
                    qty: '',
                    newqty: '',
                    shcode: '',
                    mnt: ''
                })
            }

        } catch (e) {
            console.log(e)
        }


    }
    const [err, setErr] = useState({})
    const create = async (e) => {
        try {
            e.preventDefault();

            let t = {
                from: false,
                name: false,
                qty: false,
                type: false,
                comment: false,
            }
            // console.log(newProd, prodArr, prodOne)


            if (newProd.type == 'Прочее' && newProd.type == 'Возврат') {
                if (!newProd.from) {
                    t.from = 'Контрагент обязателен!'

                }
            }
            if (!newProd.type) {
                t.type = 'Розничная цена обязательна к заполнению!'
            }
            // if (!newProd.comment) {
            //     t.comment = 'Розничная цена обязательна к заполнению!'
            // }

            setErr(t)
            if (
                newProd.type &&

                !t.name &&
                !t.from &&
                !t.qty &&
                !t.type &&
                !t.comment

            ) {

                setLoading(true)

                const newProd5 = await $api.post(`${BACKURL}/api/utbteam/history/prihod`,
                    { newProd: newProd, prodArr: prodArr }
                )

                let prelist = []

                for (let i = 0; i < newProd5.data.product.length; i++) {
                    prelist[i] = ({
                        shcode: newProd5.data.product[i].shcode,
                        kategory: newProd5.data.product[i].kategory,
                        name: newProd5.data.product[i].name,
                        mnt: newProd5.data.product[i].mnt,
                        brakqty: newProd5.data.product[i].brakqty,

                        price: Number(newProd5.data.product[i].price),
                        optprice: Number(newProd5.data.product[i].optprice),
                        id: Number(newProd5.data.product[i].id),
                        quantidade: 1,
                        vipprice: newProd5.data.product[i].vipprice.map(pf => JSON.parse(pf)),
                        // planedqty:prod.data[i].planedqty,
                        photo: newProd5.data.product[i].photo,
                        text: newProd5.data.product[i].about,
                        recomendation: newProd5.data.product[i].recomendation,
                        scladqty: newProd5.data.product[i].scladqty,
                    })

                }



                setSclad(prelist)
                setServerProd(prelist);
                setScladHistory([...scladHistory, newProd5.data.history])
                setNewProd({
                    from: "",
                    kategory: 'Приход',
                    type: '',
                    comment: '',
                })
                setProdArr([])
                setLoading(false)

                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            setLoading(false)

            // console.log(e)
        }

    }


    return (<>
        <br />
        <div className={style.container_form}>
            <form className={style.form}>




                <label for="pet-select">Выберите причину прихода:</label>

                <select value={newProd.type} name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, type: event.target.value });
                }}>
                    <option value="">--Выберите причину--</option>
                    <option value="Выпуск продукции">Выпуск продукции</option>
                    <option value="Возврат">Возврат</option>
                    <option value="Возврат-Брак">Возврат-Брак</option>
                    <option value="Прочее">Прочее</option>
                </select>

                {err.type && (
                    <p style={{ color: "red" }}>{err.type}</p>
                )}

                {newProd.type.includes('Возврат') && (<>
                    <br></br>

                    <label for="pet-select">Выберите контрагента:</label>

                    <select value={newProd.from} name="from" id="pet-select" onChange={(event) => {
                        setNewProd({ ...newProd, from: event.target.value });
                    }}>
                        <option value="">--Выберите контрагента--</option>

                        {optUsers.map(
                            (prod, index) => <>
                                <option value={prod.org}>{prod.org}</option>

                            </>)}


                    </select>

                    {err.from && (
                        <p style={{ color: "red" }}>{err.from}</p>
                    )}
                </>
                )}
                {newProd.type == 'Прочее' && (<>
                    <br></br>

                    <label for="pet-select">Введите откуда пришел товар:</label>
                    <input value={newProd.from} type="text" id="from" name="from" onChange={(event) => {
                        setNewProd({ ...newProd, from: event.target.value });
                    }} />

                    {err.from && (
                        <p style={{ color: "red" }}>{err.from}</p>
                    )}
                </>
                )}
                <br></br>



                <div className={style.border}>
                    <div style={{ overflowX: 'auto' }}>

                        <table className={style.table}>
                            <thead className={style.tableth}>

                                <tr>
                                    <td className={style.tabletd}>ШК</td>
                                    <td className={style.tabletd}>Наименоване</td>


                                    <td className={style.tabletd}>Кол-во</td>
                                    <td className={style.tabletd}>Ед</td>
                                    <td className={style.tabletd}>Удалить</td>

                                </tr>
                            </thead>
                            <tbody>
                                {prodArr.map(
                                    (prod, index) => <>
                                        <tr>


                                            <td className={style.tabletd}>{prod.shcode} </td>
                                            <td className={style.tabletd}>  {prod.name}</td>
                                            <td className={style.tabletd}>  {prod.qty}</td>
                                            <td className={style.tabletd}>  {prod.mnt}</td>
                                            <td className={style.tabletd}>
                                                <div className={style.cell}><>
                                                    <FcCancel className={style.icon} onClick={() => deleteFromList(prod.prod_id)} />
                                                </> </div>
                                            </td>




                                        </tr>

                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <label for="prod">Выберите Товар:</label>

                    <select name="pets" id="prod"
                        value={prodOne.prod_id}
                        onChange={(event) => {
                            const pr = sclad.find(pr => pr.id == event.target.value)
                            let test = {
                                prod_id: pr.id,
                                name: pr.name,
                                kategory: pr.kategory,
                                oldqty: pr.scladqty,
                                newqty: prodOne.newqty,
                                qty: prodOne.qty,
                                shcode: pr.shcode,
                                mnt: pr.mnt
                            }
                            console.log(test)
                            setOneProd(test)
                            // setProd(event.target.value);
                        }}
                    >
                        <option value="">--Выберите Продукт--</option>

                        {sclad.map(
                            (prod, index) => {
                                return <option value={prod.id}>{prod.name} - Доступно {prod.scladqty} шт</option>
                            }
                        )}
                    </select>
                    {err2.name && (
                        <p style={{ color: "red" }}>{err2.name}</p>
                    )}
                    <br></br>

                    <label for="from">Введите количество:</label>
                    <input value={prodOne.qty} type="text" id="from" name="from" onChange={(event) => {

                        // const pr = sclad.find(pr => pr.id == event.target.value)

                        setOneProd({ ...prodOne, qty: event.target.value, newqty: Number(event.target.value) + Number(prodOne.oldqty) });
                    }} />
                    {err2.qty && (
                        <p style={{ color: "red" }}>{err2.qty}</p>
                    )}
                    <Button text='Добавить в список!' onClick={addOne} />

                </div>
                <br></br>
                <label for="name">Введите Комментарий:</label>
                <input value={newProd.comment} type="text" id="name" name="name" onChange={(event) => {
                    setNewProd({ ...newProd, comment: event.target.value });
                }} />
                {err.comment && (
                    <p style={{ color: "red" }}>{err.comment}</p>
                )}
                <br></br>


                <br></br>

                {!loading && (<>
                    <Button text='Создать!' onClick={create} />
                </>
                )}
                {loading && (
                    <Button text='Создаем...' disabled={true} />

                )}            </form>
        </div>
        {loading&&(
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
    </>
    )
}
export default Prihod