import React, { useEffect, useContext, useState } from "react";
import { produtos } from "./JsonProdutosDry";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Title from '../Title'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
const ForDogsData = ({ amount }) => {

  // const “ = JSON.parse(localStorage.getItem("products"));
  const { serverProd, produtosSelecionados, opt, tabs, setTabs, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
  const navigate = useNavigate()
  const docgs = serverProd.filter((prod) => (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак")&&prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    if (a.scladqty > 0 && b.scladqty === 0) return -1;
    if (a.scladqty === 0 && b.scladqty > 0) return 1;
    
    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  // const cats = serverProd.filter((prod) => (prod.forwho == "Для кошек" || prod.forwho == "Для кошек и собак") && prod.brand != 'KotoPes')
  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const changeItensCart = (id) => {
    const produtos = serverProd

    const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
    const produtoDados = produtos.find((produto) => produto.id == +id);



    const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  const drydey = serverProd.filter((prod) => (
    // prod.forwho == "Для собак" && 
    prod.kategory.includes("сухой корм") && prod.forwho == "Для собак")&&prod.showed).sort((a, b) => {
      // Сначала сортируем по условию scladqty > 0
      if (a.scladqty > 0 && b.scladqty === 0) return -1;
      if (a.scladqty === 0 && b.scladqty > 0) return 1;
      
      // Если оба объекта в одной группе, сортируем по name
      return a.name.localeCompare(b.name);
    });

  const shampoodog = serverProd.filter((prod) => prod.podkategory == "Шампунь" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак")&&prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    if (a.scladqty > 0 && b.scladqty === 0) return -1;
    if (a.scladqty === 0 && b.scladqty > 0) return 1;
    
    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const aptekadog = serverProd.filter((prod) => prod.kategory == "ВетАптека" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак")&&prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    if (a.scladqty > 0 && b.scladqty === 0) return -1;
    if (a.scladqty === 0 && b.scladqty > 0) return 1;
    
    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  const toys = serverProd.filter((prod) => prod.podkategory == "Игрушки" && (prod.forwho == "Для собак" || prod.forwho == "Для кошек и собак")&&prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    if (a.scladqty > 0 && b.scladqty === 0) return -1;
    if (a.scladqty === 0 && b.scladqty > 0) return 1;
    
    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });
  // const shampoocat = serverProd.filter((prod) => prod.podkategory == "Шампунь" && (prod.forwho == "Для кошек" || prod.forwho == "Для кошек и собак"))
  // const vccat = serverProd.filter((prod) => prod.podkategory == "Наполнитель" && (prod.forwho == "Для кошек" || prod.forwho == "Для кошек и собак"))
  // const aptekablohi = apteka.filter((prod) => prod.podkategory == "От блох и клещей")
  // console.log(serverProd, serverProd)
  const GoInfo = (imageId, name) => {
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd: serverProd,

        produtosSelecionados: produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/fordogs"
      }
    });
  };

  // let tt = photo[0]
  // if(!photo){
  //   tt = ''
  // }
  // console.log(drydey)
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    if (tabs == 'сухой корм') {
      setSelectedIndex(1)
    }
    if (tabs == 'Шампунь') {
      setSelectedIndex(2)
    }

    if (tabs == 'ВетАптека') {
      setSelectedIndex(3)
    }
    if (tabs == 'Игрушки') {
      setSelectedIndex(4)
    }
  }, [tabs])
  // switch (true) {
  //   case (kat == 'сухой корм'):
  //     setSelectedIndex(1)
  //     break
  //   case (kat == 'Шампунь'):
  //     setSelectedIndex(2)
  //     break

  //   case (kat == 'ВетАптека'):
  //     setSelectedIndex(3)
  //     break
  //   default:
  //     setSelectedIndex(0)
  //     break
  // }
  const handleSelect = (index) => {
    if (index == 1) {
      setTabs('сухой корм')
    }
    if (index == 2) {
      setTabs('Шампунь')
    }

    if (index == 3) {
      setTabs('ВетАптека')
    }
    if (index == 4) {
      setTabs('Игрушки')
    }
    if (index == 0) {
      setTabs('все товары')
    }
    setSelectedIndex(index);
  };
  return (
    <>



      <Tabs selectedIndex={selectedIndex} onSelect={handleSelect} >
        <TabList style={{
          background: 'none',

          borderRadius: '25px',
          textAlign: 'center',
          display: 'flex',
          position: 'initial',
          margin: '15px',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-evenly'
        }}>

          <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Все Товары</b></Tab>
          <Tab style={{
            margin: '15px',
            position: 'initial',
            borderBottom: '1px solid #0000',
            fontSize: '20px',

            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderRadius: '25px',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Сухой Корм</b></Tab>
          <Tab style={{
            fontSize: '20px',

            margin: '15px',
            position: 'initial',
            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid #0000',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Шампуни</b></Tab>
          <Tab style={{
            fontSize: '20px',

            margin: '15px',
            position: 'initial',
            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid #0000',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>ВетАптека</b></Tab>
          <Tab style={{
            fontSize: '20px',

            margin: '15px',
            position: 'initial',
            borderRadius: '25px',
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid #0000',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}><b>Игрушки</b></Tab>

        </TabList>
        <TabPanel>
          <br />
          <div className={style.produtos_container}>
            {!opt.auth && docgs.map(
              ({ photo, name, price, forwho, id, scladqty }, index) => {
                let tt = ''
                if (photo[0]) {
                  tt = photo[0]
                }
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                return index <= amount && (
                  <div key={id} id={id} className={style.cards_produtos} style={{opacity:scladqty==0?"0.5":"1"}}>
                    {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                    {/* <img id={id} className={style.img} src={tt} /> */}
                    <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                      <LazyLoadImage
                        id={id}
                        className={style.img}
                        src={tt}
                        alt="Example Image"
                        height="100%"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                    </div>
                    {/* </div> */}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(scladqty) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(scladqty) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}

                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(scladqty) > 0 && (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(scladqty) > 0 && (<>
                        <Button width={'100px'} text="В Корзину" style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button width={'100px'} text="Удалить из Корзины " style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
                  </div>
                )
              }
            )}

            <br />
          </div>
        </TabPanel>
        <TabPanel>
          <br />
          <div className={style.produtos_container}>
            {!opt.auth && drydey.map(
              ({ photo, name, price, forwho, id, scladqty }, index) => {
                let tt = ''
                if (photo[0]) {
                  tt = photo[0]
                }
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                return index <= amount && (
                  <div key={id} id={id} className={style.cards_produtos} style={{opacity:scladqty==0?"0.5":"1"}}>
                    {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                    {/* <img id={id} className={style.img} src={tt} /> */}
                    <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                      <LazyLoadImage
                        id={id}
                        className={style.img}
                        src={tt}
                        alt="Example Image"
                        height="100%"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                    </div>
                    {/* </div> */}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(scladqty) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(scladqty) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}

                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(scladqty) > 0 && (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(scladqty) > 0 && (<>
                        <Button width={'100px'} text="В Корзину" style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button width={'100px'} text="Удалить из Корзины " style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
                  </div>
                )
              }
            )}

            <br />
          </div>
        </TabPanel>
        <TabPanel>
          <br />
          <div className={style.produtos_container}>
            {!opt.auth && shampoodog.map(
              ({ photo, name, price, forwho, id, scladqty }, index) => {
                let tt = ''
                if (photo[0]) {
                  tt = photo[0]
                }
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                return index <= amount && (
                  <div key={id} id={id} className={style.cards_produtos} style={{opacity:scladqty==0?"0.5":"1"}}>
                    {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                    {/* <img id={id} className={style.img} src={tt} /> */}
                    <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                      <LazyLoadImage
                        id={id}
                        className={style.img}
                        src={tt}
                        alt="Example Image"
                        height="100%"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                    </div>
                    {/* </div> */}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(scladqty) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(scladqty) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}


                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(scladqty) > 0 && (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(scladqty) > 0 && (<>
                        <Button text="В Корзину" width={'100px'} style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button text="Удалить из Корзины " width={'100px'} style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
                  </div>
                )
              }
            )}

            <br />
          </div>
        </TabPanel>
        <TabPanel>
          <br />
          <div className={style.produtos_container}>
            {!opt.auth && aptekadog.map(
              ({ photo, name, price, forwho, id, scladqty }, index) => {
                let tt = ''
                if (photo[0]) {
                  tt = photo[0]
                }
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                return index <= amount && (
                  <div key={id} id={id} className={style.cards_produtos} style={{opacity:scladqty==0?"0.5":"1"}}>
                    {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                    {/* <img id={id} className={style.img} src={tt} /> */}
                    <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                      <LazyLoadImage
                        id={id}
                        className={style.img}
                        src={tt}
                        alt="Example Image"
                        height="100%"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                    </div>
                    {/* </div> */}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(scladqty) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(scladqty) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}


                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(scladqty) > 0 && (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(scladqty) > 0 && (<>
                        <Button text="В Корзину" width={'100px'} style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button text="Удалить из Корзины " width={'100px'} style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
                  </div>
                )
              }
            )}

            <br />
          </div>
        </TabPanel>
        <TabPanel>
          <br />
          <div className={style.produtos_container}>
            {!opt.auth && toys.map(
              ({ photo, name, price, forwho, id, scladqty }, index) => {
                let tt = ''
                if (photo[0]) {
                  tt = photo[0]
                }
                const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
                return index <= amount && (
                  <div key={id} id={id} className={style.cards_produtos} style={{opacity:scladqty==0?"0.5":"1"}}>
                    {/* <div style={{alignItems: 'center', display: 'flex', height: '300px', width: '300px'}}> */}
                    {/* <img id={id} className={style.img} src={tt} /> */}
                    <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                      <LazyLoadImage
                        id={id}
                        className={style.img}
                        src={tt}
                        alt="Example Image"
                        height="100%"
                        effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                      />
                    </div>
                    {/* </div> */}
                    <p onClick={() => GoInfo(id, name)} id={id}>{name}</p>
                    <div onClick={() => GoInfo(id, name)} className={style.vbadgecont}>
                      {forwho == 'Для собак' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐶<span></span>
                        </div>
                      )}
                      {forwho == 'Для кошек' && (
                        <div href="#" className={style.vbadge} style={{ boxShadow: 'none', fontSize: '50px', border: 'none', 'background': 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          🐱<span></span>
                        </div>
                      )}
                      {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': '#04b31b', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          ХИТ
                          <span></span>
                        </div>
                      )}
                      {Number(scladqty) > 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {price}RUB
                          <span></span>
                        </div>}
                      {Number(scladqty) <= 0 &&
                        <div href="#" className={style.vbadge} style={{ height: '30px', 'background': 'var(--primary-color)', 'color': 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Распродано!
                          <span></span>
                        </div>}


                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb && Number(scladqty) > 0 && (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb && Number(scladqty) > 0 && (<>
                        <Button text="В Корзину" width={'100px'} style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button text="Удалить из Корзины " width={'100px'} style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
                  </div>
                )
              }
            )}

            <br />
          </div>
        </TabPanel>

      </Tabs >



    </>
  );
};

export default ForDogsData;
