import { createContext, useEffect, useState } from "react";
import { useNavigate,useParams,Navigate } from "react-router-dom";
import $api from "./http/index.ts";
import { BACKURL } from './conf.js'

const ReirectQR = () => {
    const navigate = useNavigate();
    let { name } = useParams();
    console.log(name)
    useEffect(()=>{
        const test = async (name)=>{
                        let prod = await $api.get(`${BACKURL}/api/utbteam/qr/addone/${name}`)

        }
test(name)
    },[])

    // const handleClick = () => {
       
    // }
// navigate("/");
    return  <Navigate to='/' />
        // <div>
        //     <button onClick={handleClick} type="button" />
        // </div>
    
}

export default ReirectQR;