import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../../../Modal/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../../../conf.js'
const CunsDots = ({dot}) => {
    const { scladDot, setScladDot,optUsers, admin, sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const cuns = scladDot.filter((post) => post.kategory.includes('консервы')&&post.dot==dot)

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    // const [thisChange, setThisChange] = useState({})

    // const edit = async (prod, q, p, o, about, name, recomendation, shcode, vipprice, optid) => {
    //     setThisChange({
    //         prod: prod,
    //         price: q,
    //         priceValue: '',
    //         optprice: o,
    //         optValue: '',
    //         qty: p,
    //         qtyValue: '',
    //         about: about,
    //         aboutValue: '',
    //         name: name,
    //         nameValue: '',
    //         recomendation: recomendation,
    //         recomendationValue: '',
    //         shcode: shcode,
    //         shcodeValue: '',
    //         vipprice: vipprice,
    //         vippriceOptUser: optid,
    //         vippriceValue: ''

    //     })
    //     setModalEdit(true)

    // }
    // const deleteProd = async (id) => {
    //     const DeletedId = await $api.get(`${BACKURL}/api/utbteam/deleteproduct/${id}`)
    //     const newprod = sclad.filter((post) => post.id != id)
    //     setSclad(newprod)
    //     alert('Товар удален! Эта функция будет ограничена!')
    // }
    // const submit = async (func, data) => {
    //     confirmAlert({
    //         title: 'Подтвердите, чтобы продолжить!',
    //         message: 'Вы уверены, в том, что хотите удалить ордер?',
    //         buttons: [
    //             {
    //                 label: 'Да!',
    //                 onClick: async () => await func(data)
    //             },
    //             {
    //                 label: 'Нет!',
    //                 //   onClick: () => alert('Click No')
    //             }
    //         ],
    //         closeOnEscape: true,
    //         closeOnClickOutside: true,
    //     });
    // };

    return (<>
        <div style={{ overflowX: 'auto' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>Код</td>
                        <td className={style.tabletd}>Наименоване</td>
                        <td className={style.tabletd}>Категория</td>
                        <td className={style.tabletd}>Остаток</td>
                        {/* <td className={style.tabletd}>Розница</td> */}
                       
                    </tr>
                </thead>
                <tbody>
                    {cuns.map(
                             (prod, index) => <>
                             <tr>
                                 <td className={style.tabletd}>
 
                                     <div className={style.cell}><>
                                  
                                         {prod.shcode}
                                     </> </div>
 
 
                                 </td>
 
                                 <td className={style.tabletd}>
                                     <div className={style.cell}><>
                                    
                                         {prod.name}
                                     </> </div>
 
 
                                 </td>
                                 <td className={style.tabletd}>  {prod.kategory}</td>
                               
 
                                 <td className={style.tabletd}>
                                     <div className={style.cell}><>
                                   
                                         {prod.scladqty}шт
                                     </> </div>
 
 
 
                                 </td>
                                 {/* <td className={style.tabletd}>
                                     <div className={style.cell}><>
                                  
                                         {prod.price}RUB
                                     </> </div>
 
 
                                 </td> */}
                               
 
 
                          
 
 
                             </tr>
 
                         </>
                    )}
                </tbody>
            </table>
            {/* {modalEdit && (
                <ModalEdit
                    thisChange={thisChange}
                    setThisChange={setThisChange}
                // prod = {prod}
                />

            )} */}
        </div>
    </>
    )
}
export default CunsDots