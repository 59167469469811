import React, { useContext, useEffect, useState } from "react";
import Produtos from '../../Produtos/Produtos'
import Title from '../../Title'
import style from './Sobre.module.css'
import ProdutosSuhKorma from "../../Produtos/ProdutosSuhKorma";

const SuhKorma = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])
    return (
    <div className={style.container_geral_prod}>
      <Title text="Сухие Корма"/>
      {/* <div className={style.produtos_container}> */}
        <ProdutosSuhKorma amount={112} columns={5} sizeContainer={1200}/>
      {/* </div> */}
    </div>
  );
};

export default SuhKorma;
