import React from "react";
import Button from "../Button/Button";
import { Link } from 'react-router-dom';
// import style from "./infos.css"
const Infos = () => {


  return (
    <div style={{ 
      'display': 'flex',
      'align-content': 'space-between',
      'align-items': 'center',
      'height': '350px',
      'justify-content': 'space-between',
      'flex-direction': 'column',
      'borderRadius':'30px' }}>
      <div >
        <h1 style={{ paddingTop:'100px','text-shadow': '#20b6e8 2px 2px 0px','font-size': '4em', color: 'var(--primary-color)' }}>
          КотоРёs
        </h1>
      </div>
      <div>
        <h1 style={{ 'text-shadow': '#20b6e8 2px 2px 0px' }}>
           <b>НАТУРАЛЬНЫЙ</b>  корм для домашних животных!
        </h1>
      </div>
      {/* <br></br> */}
      {/* <h3>
        Больше 5 лет нас выбирают и рекомендуют!
      </h3> */}
      {/* <br></br>
      <Link to="/contacts">
        <Button text='Контакты'></Button>
      </Link> */}
    </div>
  );
};

export default Infos;
