import React, { useEffect, useContext, useState } from "react";
import { produtos } from "./JsonProdutosDry";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate } from "react-router-dom";
import Title from '../Title'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const AmunitionData = ({ amount }) => {
  // const “ = JSON.parse(localStorage.getItem("products"));
  const { serverProd, produtosSelecionados, opt } = useContext(QtdContext);
  const navigate = useNavigate()
  const drydey = serverProd.filter((prod) => prod.kategory == "Амуниция"&&prod.showed)

  const GoInfo = (imageId, name) => {
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd: serverProd,

        produtosSelecionados: produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/amunation"
      }
    });
  };

  // let tt = photo[0]
  // if(!photo){
  //   tt = ''
  // }


  return (
    <>  <br />
      <div className={style.borders}>
        <br />
        <p className={style.p}>
          Также представляем вашему вниманию линейку натуральных свежемороженных рационов  торговой марки КотоPes — сбалансированное и здоровое питание для ежедневного кормления ваших домашних питомцев. 8 рационов для собак всех пород и 2 рациона для кошек! Изготовлено из 100% натуральных продуктов, используемых в питании человека (свежие ингредиенты категории Human Grade – человеческий стандарт качества). Структура и компонентный состав рационов удовлетворяют потребность в необходимых питательных веществах и энергии.      </p>
        <br />
        <p className={style.p}>
          Важно: рационы не содержат консерванты, усилители вкуса, красители, ароматизаторы и зерновые культуры. ТОЛЬКО НАТУРАЛЬНЫЕ МЯСНЫЕ ИНГРЕДИЕНТЫ И ОВОЩИ!      </p>
        <br />
      </div>
      {/* <Title text="Содержание и Уход" /> */}
      <br />
      {/* <div className={style.produtos_container}>
        {!opt.auth && drydey.map(
          ({ photo, name, price, id, scladqty }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            return index <= amount && (
              <div key={id} id={id} onClick={() => GoInfo(id, name)} className={style.cards_produtos}>
                <img id={id} className={style.img} src={tt} />
                <p id={id}>{name}</p>
                <div className={style.vbadgecont}>

                  <div href="#" className={style.vbadge}>
                    NEW
                    <span></span>
                  </div>
                  {Number(scladqty) > 0 &&
                    <div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      {price}RUB
                      <span></span>
                    </div>}
                  {Number(scladqty) <= 0 &&
                    <div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      Распродано!
                      <span></span>
                    </div>}

                  <div href="#" className={style.vbadge} style={{ 'background': '#20b6e8' }}>
                    Натуральный
                    <span></span>
                  </div>
                </div>
              </div>
            )
          }
        )}
        {opt.auth && drydey.map(
          ({ photo, name, vipprice, optprice, id }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
            return index <= amount && (
              <div key={id} id={id} onClick={() => GoInfo(id, name)} className={style.cards_produtos}>
                <img id={id} className={style.img} src={tt} />
                <p id={id}>{name}</p>
                <p id={id} className={style.price}><b>{optprice}RUB</b></p>
                <div className={style.vbadgecont}>
                  <div href="#" className={style.vbadge}>
                    NEW
                    <span></span>
                  </div>
                  {vipprice.find((vp) => vp.optuserid == opt.user.id) && (vipprice.find((vp) => vp.optuserid == opt.user.id)).vipprice > 0 ? (
                    <div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      {console.log((vipprice.find((vp) => vp.optuserid == opt.user.id)))}
                      {(vipprice.find((vp) => vp.optuserid == opt.user.id)
                      ).vipprice}RUB
                      <span></span>
                    </div>
                  ) :

                    (<div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                      {optprice}RUB
                      <span></span>
                    </div>)
                  }

                  <div href="#" className={style.vbadge} style={{ 'background': '#20b6e8' }}>
                    Натуральный
                    <span></span>
                  </div>
                </div>
              </div>
            )
          }
        )}
        <br />
      </div> */}

    </>
  );
};

export default AmunitionData;
