import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { BACKURL } from '../../../../../conf.js'
const Modalpromo = ({ thisChange, setThisChange, prod }) => {

  const navigate = useNavigate();
  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { modalPromo, setModalPromo,PromoCodes, setPromoCodes, setSclad, setServerProd, modalEdit, setModalEdit, admin } = useContext(QtdContext);
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container1112" && setModalPromo(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  // const [isToggled, toggle] = useState(toggled);

  const editCode = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/promocodes/updatecode/${thisChange.promo.id}`,
      { code: thisChange.codeValue, admin: admin.user }
    )
    const newPosts = PromoCodes.map((post) => (
      post.id == thisChange.promo.id
        ? { ...post, code: newProd.data.code }
        : post
    ));

    setPromoCodes(newPosts)


    alert('Код успешно обновлен в системе!')
    setModalPromo(false)
  }

  const editSale = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/promocodes/updatesale/${thisChange.promo.id}`,
      { sale: thisChange.saleValue, admin: admin.user }
    )
    const newPosts = PromoCodes.map((post) => (
      post.id == thisChange.promo.id
        ? { ...post, sale: newProd.data.sale }
        : post
    ));

    setPromoCodes(newPosts)


    alert('Скидка успешно обновлен в системе!')
    setModalPromo(false)
  }

  




  return (
    <>
      {modalPromo && (
        <div onClick={closeModal} id="container1112" className={style.modal_container}>
          {/* <div className={style.container_modal}> */}

          {/* TEST */}
          {thisChange.code && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.promo.code}`}></Title>
              </div>
              <form className={style.form}>
                <label for="кол-во">Введите новый код:</label>
                <input type="text" id="кол-во" name="кол-во" onChange={(event) => {
                  setThisChange({ ...thisChange, codeValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить код"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editCode}
              />
            </div>
          )}

          {thisChange.sale && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.sale}`}></Title>
              </div>
              <form className={style.form}>
                <label for="кол-во">Введите новую скидку:</label>
                <input type="number" id="кол-во" name="кол-во" onChange={(event) => {
                  setThisChange({ ...thisChange, saleValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить скидку"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editSale}
              />
            </div>
          )}

        </div>

        // </div>
      )}

    </>
  );
};

export default Modalpromo;
