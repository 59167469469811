import React from 'react'
import ProdutosDry from '../../Produtos/ProdutosDry'
import Title from '../../Title'
import style from './Loja.module.css'
import { useEffect, useState, useContext } from "react";
import ForCatsData from '../../Produtos/ForCatsData';
import catgif from '../../Assets/catgif.gif'
import catgif1 from '../../Assets/catgif1.gif'
import catgif2 from '../../Assets/catgif2.gif'
import { QtdContext } from "../../../context/qtdContext";

const ForCats = () => {
  const { serverProd, produtosSelecionados, menu, setMenu,tabs, setTabs } = useContext(QtdContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setMenu(false)
  }, [])
  const getRandomElement = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };
  
  // Получаем случайный элемент
  const randomElement = getRandomElement([catgif, catgif1, catgif2]);
  return (
    <div className={style.container_geral_prod}>
      {/* <Title text="Каталог товаров"/> */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '200px'
      }}>
        <img style={{
          height: '100px',
          marginTop: '25px'
        }} src={randomElement} alt="My GIF" />
      </div>
      {/* <p className={style.p}>
        Наша продукция прошла лабораторные исследования, имеет ветеринарный  сертификат и декларацию соответствия.
      </p> */}
      {/* <div className={style.produtos_container}> */}
      <ForCatsData amount={112} columns={5} sizeContainer={1200} />
      {/* </div> */}
    </div>
  )
}

export default ForCats