import axios from 'axios';
import {BACKURL} from '../conf.js'
// import {store} from "../index";
// import {IUser} from "../models/IUser";
// import 'dotenv/config'
// import { QtdContext } from "../context/qtdContext";
// import { useEffect, useState, useContext } from "react";
export const API_URL = `${BACKURL}/api`


const $api = axios.create({
    credentials: true,
    baseURL: API_URL
    
})
// const { opt } = useContext(QtdContext);
$api.defaults.withCredentials = true
$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    // console.log(config)
    return config;
})

$api.interceptors.response.use((config) => {
    return config;
},async (error) => {
    // console.log(error);
    const originalRequest = error.config;
    if (error.response.status == 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
            localStorage.setItem('token', response.data.accessToken);
            return $api.request(originalRequest);
        } catch (e) {
            // console.log('НЕ АВТОРИЗОВАН')
        }
    }
    throw error;
})

export default $api;