import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
import $api from "../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../Modal/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../conf.js'
import ModalEditOptUser from '../Modal/ModalOptUser';
const Admins = () => {
    const { admins, setAdmins,optUsers, admin, setOptUsers, setSclad, modalEdit, setModalEdit, modalEditOptUser, setModalEditOptUser } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    // const [thisChangeOpt, setThisChangeOpt] = useState({})

    // const edit = async (prod, login, pass, email, adress, org, inn, phone) => {
    //     setThisChangeOpt({
    //         prod: prod,
    //         login: login,
    //         loginValue: '',
    //         pass: pass,
    //         passValue: '',
    //         email: email,
    //         emailValue: '',
    //         adress: adress,
    //         adressValue: '',
    //         org: org,
    //         orgValue: '',
    //         inn: inn,
    //         innValue: '',
    //         phone: phone,
    //         phoneValue: '',

    //     })
    //     setModalEditOptUser(true)

    // }
    // const deleteOptUser = async (id) => {
    //     const DeletedId = await $api.get(`${BACKURL}/api/utbteam/deleteoptuser/${id}`)
    //     const newprod = optUsers.filter((post) => post.id != id)
    //     setOptUsers(newprod)
    //     alert('Оптовик удален! Эта функция будет ограничена!')
    // }
    // const submit = async (func, data) => {
    //     confirmAlert({
    //         title: 'Подтвердите, чтобы продолжить!',
    //         message: 'Вы уверены, в том, что хотите удалить оптовика?',
    //         buttons: [
    //             {
    //                 label: 'Да!',
    //                 onClick: async () => await func(data)
    //             },
    //             {
    //                 label: 'Нет!',
    //                 onClick: () => alert('Cancel')
    //             }
    //         ],
    //         closeOnEscape: true,
    //         closeOnClickOutside: true,
    //     });
    // };

    return (<>
        <div style={{ overflowX: 'auto' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                    <td className={style.tabletd}>id</td>
                    <td className={style.tabletd}>Имя</td>
                        <td className={style.tabletd}>Логин</td>
                        <td className={style.tabletd}>Пароль</td>
                        <td className={style.tabletd}>Email</td>
                        <td className={style.tabletd}>Тел</td>
                        <td className={style.tabletd}>tgUsername</td>
                        <td className={style.tabletd}>tgChatId</td>
                        <td className={style.tabletd}>role</td>

                    </tr>
                </thead>
                <tbody>
                    {admins.map(
                        (prod, index) => <>
                            <tr>
                                <td className={style.tabletd}>{prod.id}</td>

                               

                                <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.firstname}</td>
                                    <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.login}</td>
                                    <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.pass}</td>
                                    <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.email}</td>
                                    <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.phone}</td>
                                    <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.tgUsername}</td>
                                    <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.tgChatId}</td>
                                    <td className={style.tabletd}>
                                    {admin.role == 'GOD' && (
                                        <FaEdit 
                                        // onClick={() => { edit(prod, false, true, false, false, false, false, false) }} className={style.icon} 
                                        />

                                    )}
                                    {prod.role}</td>
                                  
                            </tr>

                        </>
                    )}
                </tbody>
            </table>
            {/* {modalEditOptUser && (
                <ModalEditOptUser
                    thisChange={thisChangeOpt}
                    setThisChange={setThisChangeOpt}
                // prod = {prod}
                />

            )} */}
        </div>
    </>
    )
}
export default Admins