import React, { useEffect, useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Cart from "../Cart/Cart";
import Button from '../Button/Button'
import { QtdContext } from "../../context/qtdContext";
import $api from "../../http/index.ts";

import style from "./LinksMenu.module.css";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Cards from "../Cards/Cards.js";
import CardsForDog from "../Cards/CardsForDog.js";
import CardsForCat from "../Cards/CardsForCat.js";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const LinksMenuMobileComponet = (closeModal) => {
  const location = useLocation()
  // const { auth, setAuth } = useContext(QtdContext);
  const { menu, setMenu } = useContext(QtdContext);

  let test = true
  if ((location.pathname == "/createorder" || location.pathname == "/success")) {
    test = false
  }
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className={style.menu_container}>
      <div style={{ textShadow: '#20b6e8 2px 2px 0px;',  color: 'black', width: '100%' }}>
      
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography className={style.ulmobitem}>Наша продукция</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ background: "url(https://api.kotopes39.ru/static/newback.png)" }}>
            <Cards />
        
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Для Собак</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ background: "url(https://api.kotopes39.ru/static/newback.png)" }}>
            <CardsForDog />

          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Для Кошек</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ background: "url(https://api.kotopes39.ru/static/newback.png)" }}>
            <CardsForCat />

          </AccordionDetails>
        </Accordion>
       
      </div>
   
      {test && (
        <div className={style.cart}>
          <Cart />
        </div>
      )}
    </div>
  );
};

export default LinksMenuMobileComponet;