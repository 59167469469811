import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import {BACKURL} from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';

const ModalEditSpec = ({ id,prod, name }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const {modalInfoSpec, servOrders, setSpec,sclad, setModalInfoSpec,setServOrders, admin, setModalInfo, infomodal, modalEditSpec, setModalEditProduct } = useContext(QtdContext);
  const [newValue, setNeValue] = useState('')
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12488" && modalEditSpec(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState(false)
  // const scladqty = sclad.find(prod=>prod.name==name)

  const editPrice = async () => {
    let t = false
    // const test = sclad.find(prod => prod.name == name)
    // if (newValue > test.scladqty) {
    //   t = `На складе всего ${test.scladqty} шт!`
    // }
    if (!newValue) {
      t = `Введите число!`

    }

    // if (!Number.isInteger(newValue)) {
    //   t = `Введите число!`

    // }

    setErr(t)
    if (newValue && !t) {

      // console.log(name, newValue, infomodal.order_id)
      const newProd = await $api.post(`${BACKURL}/api/utbteam/editspecproduct/${id}`,
        { name: name, newValue: newValue,admin:admin.user }
      )
      const newPosts = await servOrders.map((post) => (
        post.id == id
          ? newProd.data
          : post
      ));

      setSpec(newPosts)
      // setSclad(newPosts)

      // //Проверь правильность данных тут
      // setServerProd(newPosts)
      // console.log(newProd.data)
      // setModalInfo(false)
      // localStorage.setItem("petNew", JSON.stringify(newPosts));
      setModalInfoSpec(false)
      alert('Кол-во успешно обновлено в спецификации!')
    }
  }



  return (
    <>
      {modalInfoSpec && (
        <div onClick={closeModal} id="container12488" className={style.modal_container}>

          <div className={style.modal}>
            <div>
              <Title text={`${name}`}></Title>
            </div>
            <form className={style.form}>

              <label for="кол-во">Введите новое кол-во!</label>
              <input type="number" id="кол-во" name="кол-во" onChange={(event) => {
                setNeValue(event.target.value)
              }} />
              {err && (
                  <p style={{ color: "red" }}>{err}</p>
                )}
            </form>
            <Button
              text="Изменить кол-во"

              background="var(--primary-color)"
              color="white"
              width="100%"
              onClick={editPrice}
            />
          </div>

        </div>

        // </div>
      )}

    </>
  );
};

export default ModalEditSpec;
