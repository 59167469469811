import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../../../Modal/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo, FcRight, FcLeft,FcUndo,FcDownload,FcSynchronize,FcFullTrash } from "react-icons/fc";
import { BACKURL } from '../../../../../../../conf.js'
import ModalInfoScladHistory from '../../../Modal/ModalInfoScladHistory';
const ScladHistory = () => {
    const { modalInfoScladHistory, setModalInfoScladHistory,optUsers, admin, scladHistory, setScladHistory, sclad, modalEdit, setSclad } = useContext(QtdContext);
    const [list, setList]= useState([])
    const th = scladHistory.sort((a, b) => a.id < b.id ? 1 : -1);
    const Opan = (list1) => {
        setList(list1)
        setModalInfoScladHistory(true)
    }
    return (<>
        <div style={{ overflowX: 'auto' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>#</td>
                        <td className={style.tabletd}>Приход/Расход</td>
                        <td className={style.tabletd}>Назначение</td>
                        <td className={style.tabletd}>Склад</td>
                        <td className={style.tabletd}></td>
                        <td className={style.tabletd}>Куда</td>
                        <td className={style.tabletd}>Адрес</td>

                        <td className={style.tabletd}>Список</td>

                        <td className={style.tabletd}>Комментарий</td>
                        <td className={style.tabletd}>Действия</td>
                        <td className={style.tabletd}>Дата</td>
                        {/* <td className={style.tabletd}>Опт с НДС</td> */}
                    </tr>
                </thead>
                <tbody>
                    {th.map(
                        (prod, index) => <>
                            <tr>
                            

                                <td className={style.tabletd}>{prod.id}</td>
                                <td className={style.tabletd}>{prod.kategory}</td>
                                <td className={style.tabletd}>{prod.type}</td>
                                <td className={style.tabletd}>  Основной склад

                                </td>
                                <td className={style.tabletd}>
                                    {prod.kategory === 'Приход' && prod.type !='Возврат' &&(<div className={style.cell}><>
                                        <FcLeft className={style.icon} />
                                    </> </div>)}
                                    {prod.kategory === 'Приход' && prod.type ==='Возврат' &&(<div className={style.cell}><>
                                        <FcUndo className={style.icon} />
                                    </> </div>)}
                                    {prod.kategory === 'Расход' && prod.type !='Обмен брака' &&prod.type !='Списание'&& (<div className={style.cell}><>
                                        <FcRight className={style.icon} />
                                    </> </div>)}
                                    {prod.kategory === 'Расход' && prod.type ==='Обмен брака'&& (<div className={style.cell}><>
                                        <FcSynchronize className={style.icon} />
                                    </> </div>)}
                                    {prod.kategory === 'Расход' && prod.type ==='Списание'&& (<div className={style.cell}><>
                                        <FcFullTrash className={style.icon} />
                                    </> </div>)}
                                    

                                </td>
                                <td className={style.tabletd}>  {prod.from}

                                </td>
                                <td className={style.tabletd}>  {prod.adress}   </td>

                                <td className={style.tabletd}>

                                    <div className={style.cell}><>
                                        <FcInfo className={style.icon} onClick={()=>Opan(prod.list)} />

                                    </> </div>


                                </td>

                                <td className={style.tabletd}>{prod.comment}</td>
                                <td className={style.tabletd}><div className={style.cell}><>
                                        <FcDownload className={style.icon} />
                                    </> </div></td>
                                <td className={style.tabletd}>{prod.createdAt}</td>

                                {/* <td className={style.tabletd}>0 RUB</td> */}



                            </tr>

                        </>
                    )}
                </tbody>
            </table>

        </div>
        {modalInfoScladHistory && 
                <ModalInfoScladHistory list={list}></ModalInfoScladHistory>

        }
    </>
    )
}
export default ScladHistory