import React from "react";
import style from "./Footer.module.css";
import dog from "../Assets/beautiful-pet.png";
import dog2 from "../Assets/lovely-pet.png";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { FaTelegram } from "react-icons/fa";
import logos from './logo3h.png'
const Footer = () => {
  return (
    <>
      <div className={style.footer}>
        <img className={style.img} src={dog} />
        <div className={style.footer_logo}>
          <Logo width={"120px"} heigth={"120px"} />
          <div className={style.container_ul}>
            {/* <ul className={style.ul}>
              <h3>СОТРУДНИЧЕСТВО</h3>
              <Link to="/">Что то 1</Link>
              <Link to="/">Что то 2</Link>
              <Link to="/">Что то 3</Link>
            </ul> */}
            <ul className={style.ul}>
              <h3 style={{'color':'blck'}}>КАТЕГОРИИ</h3>
              <Link to="/cuns">Консервная Продукция</Link>
              <Link to="/lacomstva">Лакомства</Link>
              <Link to="/racions">Рационы</Link>
            </ul>
            <ul className={style.ul}>

              <Link to="/contacts">
                <h3 style={{'color':'blck'}}>КОНТАКТЫ</h3>
                <p className={style.ajuda}>
                  Вы всегда можете  написать или позвонить нам для получения любой информации!
                </p>
              </Link>

              {/* <Link
                to="/contacts"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: 'center',
                  marginTop: "20px",
                }}
              >
                <FaTelegram className={style.icon} />
                <p>+7 (906) 768-22-33</p>
              </Link> */}
            </ul>
         
          </div>
             <div >
            <img src={logos} alt="HorizontalLogos"></img>
          </div>
          {/* <p style={{ textAlign: "center", marginTop: "35px" }}>
            Desenvolvido por{" "}
            <a href="https://www.linkedin.com/in/devrodrigomolina/" target='_blank'>Rodrigo Molina</a>
          </p> */}
        </div>
        <img className={style.img} src={dog2} />
      </div>
    </>
  );
};

export default Footer;
