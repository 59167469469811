import { useState } from 'react'
import style from './togle.css'
export const Toggle = ({ label, toggled, onClick1 }) => {
    // const [isToggled, toggle] = useState(toggled)

    // const callback = () => {
    //     toggle(!isToggled)
    //     onClick(!isToggled)
    // }

    return (
        <label className={style.elabel1}>
            <input className={style.einput1} type="checkbox" defaultChecked={toggled} onClick={onClick1} />
            <span className={style.espan1}/>
            <strong className={style.estrong1}>{label}</strong>
        </label>
    )
}