import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { BACKURL } from '../../../../../conf.js'
import QRCode from 'react-qr-code';
const ModalQRDOWN = () => {

  const navigate = useNavigate();


  const { QRcode, setQRcode, modalQRcode, setModalQRcode, modalPromo, setModalPromo, PromoCodes, setPromoCodes, setSclad, setServerProd, modalEdit, setModalEdit, admin } = useContext(QtdContext);
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container11312" && setModalQRcode(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };


  return (
    <>
      {modalQRcode && (
        <div onClick={closeModal} id="container11312" style={{'flexDirection':'column'}}className={style.modal_container}>
          {QRcode && (<>
            <div>
              <QRCode id="QRCode" title="Custom Title" value={QRcode} />
            </div>
            {/* <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
              <QRCode
                id="QRCodeScaled"
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                title="Custom Title"
                value={QRcode}
                viewBox={`0 0 256 256`}
              />
            </div> */}
            <div>
              <input type="button" value="Download QR" onClick={onImageCownload} />
              {/* <input type="text" value={QRcode} onChange={this.onValueChange} /> */}
            </div>
          </>)}




        </div>

        // </div>
      )}

    </>
  );
};

export default ModalQRDOWN;
