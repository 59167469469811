import React from 'react'
import ProdutosDry from '../../Produtos/ProdutosDry'
import Title from '../../Title'
import style from './Loja.module.css'
import { useEffect, useState, useContext } from "react";

const Loja = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])
    return (
    <div className={style.container_geral_prod}>
      <Title text="Лакомства"/>
      {/* <div className={style.produtos_container}> */}
        <ProdutosDry amount={112} columns={5} sizeContainer={1200}/>
      {/* </div> */}
    </div>
  )
}

export default Loja