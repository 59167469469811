import React, { useContext, useEffect, useState } from "react";
import style from "./ModalInfo.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { FcCancel, FcServices } from "react-icons/fc";
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../NewOrders/react-confirm-alert.css'; // Import css
import ModalEditProduct from "./ModalEditProduct";
import ModalAddProduct from "./ModalAddProduct";
import { BACKURL } from '../../../../../conf.js'
import ModalEditSpec from "./ModalEditSpec";
import ModalAddSpec from "./ModalAddSpec";
const ModalInfoSpec = ({id, reciept }) => {
console.log(reciept)
    // const navigate = useNavigate();
    // const produtosCart = JSON.parse(localStorage.getItem("pet"));
    // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

    const { modalAddSpec, setModalAddSpec,modalInfoSpec, setModalInfoSpec,opt, modalEditSpec, setModalEditSpec,servOrders,spec,setSpec, setServOrders, modalAddProduct, setModalAddProduct, modalEditProduct, setModalEditProduct, infomodal, modalInfo, setModalInfo, admin, setSclad, setServerProd, modalEdit, setModalEdit } = useContext(QtdContext);
    const closeModal = ({ target }) => {
        // console.log(modalInfo)
        target.id === "container1236" && setModalInfoSpec(false);
        // target.innerText === "ОТМЕНИТЬ" && setModal(false);
    };
    // console.log(infomodal)
    // let data = infomodal.data
 

    // console.log(infomodal)
    // console.l
    // useEffect(()=>{
    //         console.log('infomodal', infomodal)

    // },[])
    const [SoSoName, setSosoName] = useState('')
    const [SoSoProd, setSosoProd] = useState('')
    const changeQty = async (item) => {
        try {
            // console.log('change', item)
            setSosoProd(item)
            setSosoName(item.name)
            setModalEditSpec(true)
        } catch (e) {
            // console.log(e)
        }
    }
    const deleteIt = async (item) => {
        try {
            // console.log('delete', item.name, infomodal.order_id)
            const newProd = await $api.post(`${BACKURL}/api/utbteam/deletespecproduct/${id}`,
                { name: item.name, admin: admin.user }
            )
            const newPosts = await spec.map((post) => (
                post.id == id
                    ? newProd.data
                    : post
            ));

            setSpec(newPosts)
            // setSclad(newPosts)

            // //Проверь правильность данных тут
            // setServerProd(newPosts)
            // console.log(newProd.data)
            setModalInfoSpec(false)
            // localStorage.setItem("petNew", JSON.stringify(newPosts));
            // setModalEditProduct(false)
            alert('Ресурс успешно удален из спецификации!')

        } catch (e) {
            // console.log(e)
        }
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить позицию из заказа?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };


    return (
        <>
            {modalInfoSpec && (
                <div onClick={closeModal} id="container1236" className={style.modal_container}>

                    <div className={style.modal}>
                        <div>
                            <Title text='Cпецификация'></Title>
                        </div>
                      
                        {/* {infomodal.what == 'Заказ' && infomodal.type == "Розница" && (<> */}
                            {/* {status === 'Ожидает подтверждения!' && */}
                                <Button
                                    text="Добавить Продукт"

                                    background="var(--primary-color)"
                                    color="white"
                                    width="100%"
                                    onClick={() => setModalAddSpec(true)}
                                />
                            {/* } */}
                            <div style={{ overflowX: 'auto' }}>
                                <table className={style.table}>
                                    <thead className={style.tableth}>
                                        <tr>
                                            <td className={style.tabletd}>№</td>
                                            <td className={style.tabletd}>Ресурс</td>
                                            <td className={style.tabletd}>Кол-во</td>
                                            <td className={style.tabletd}>Ед</td>
                                            <td className={style.tabletd}>Действия</td>
                                   
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {reciept.map((prod, index) => {
                                            console.log(prod)
                                            const item = (JSON.parse(prod))
                                            return (<tr>
                                                <td className={style.tabletd}>{index + 1}</td>
                                                <td className={style.tabletd}>{item.name}</td>
                                                <td className={style.tabletd}>{item.qty}</td>
                                                <td className={style.tabletd}>{item.mnt}</td>
                                                <td style={{ display: 'flex', justifyContent: 'spaceAround' }} className={style.tabletd}><FcServices onClick={() => changeQty(item)} /><FcCancel onClick={() => submit(deleteIt, item)} />     </td>                                         
                                            </tr>)
                                        }

                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {modalEditSpec && (
                                <ModalEditSpec
                                    prod={SoSoProd}
                                    name={SoSoName}
                                    id={id}
                                />

                            )}
                            {modalAddSpec && (
                                <ModalAddSpec
                                id={id}

                                // status={SoSoProd}
                                // name={SoSoName}
                                />

                            )}
                        {/* </>
                        )} */}
                   
         
                    </div>

                </div>

                // </div>
            )}

        </>
    );
};

export default ModalInfoSpec;
