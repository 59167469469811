import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewProdForm.module.css'
// import Title from "../../Title";
import Button from '../../../../../Button/Button'
import $api from "../../../../../../http/index.ts";

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
// import { FaEdit } from "react-icons/fa";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../../conf.js'
const PlanedForm = () => {
    const { factory, setfactory, spec, sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    const [newProd, setNewProd] = useState({
        name: "",
        planedData: '',
        plandeQty: '',
        spec: '',

    })

    const Test = async (e) => {
        const sp = await spec.find((sp) => sp.name == e)
        setNewProd({ ...newProd, spec: sp });

    }


    const [err, setErr] = useState({})
    const create = async (e) => {
        try {
            e.preventDefault();

            let t = {
                name: false,
                kategory: false,
                mnt: false,
            }

            if (!newProd.name) {
                t.name = 'Наименование производства обязательно к заполнению!'
            }
            if (!newProd.planedData) {
                t.planedData = 'Дата производства обязательна к выбору!'
            }

            if (!newProd.plandeQty) {
                t.plandeQty = 'Количество производства обязательно к заполнению!'
            }
            if (!newProd.spec) {
                t.spec = 'Спецификация цена обязательна к выбору!'
            }


            setErr(t)
            if (newProd.name &&
                newProd.planedData &&
                newProd.plandeQty &&
                newProd.spec &&

                !t.name &&
                !t.planedData &&
                !t.plandeQty &&
                !t.spec

            ) {



                const newProd5 = await $api.post(`${BACKURL}/api/factory/addplanedfactory`,
                    newProd
                )
                // setSclad(newProd5.data)
                setNewProd({
                    name: "",
                    planedData: '',
                    plandeQty: '',
                    spec: { name: '' },

                })
                setfactory([...factory, newProd5.data])
                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            console.log(spec[0], newProd.spec)
        }

    }
    let Datas = []
    // useEffect(() => {
    var today = new Date();
    // var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
    // var dayTomorrow = tomorrow.getDate();
    // var monthTomorrow = tomorrow.getMonth() + 1; //в js месяц отсчитывается с нуля
    // var yearTomorrow = tomorrow.getFullYear();
    for (let i = 0; i < 25; i++) {
        // new Date(today.getTime() + (24 * 60 * 60 * 1000)*i)
        Datas.push(new Date(today.getTime() + (24 * 60 * 60 * 1000) * i))

    }
    // console.log(Datas)
    // }, [])

    return (<>
        <br />
        <div className={style.container_form}>
            <form className={style.form}>
                <label for="name">Введите Наименование:</label>
                <input value={newProd.name} type="text" id="name" name="name" onChange={(event) => {
                    setNewProd({ ...newProd, name: event.target.value });
                }} />
                {err.name && (
                    <p style={{ color: "red" }}>{err.name}</p>
                )}
                <br></br>
                <label for="pet-select">Выберите Планируемую дату производства:</label>

                <select value={newProd.planedData} name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, planedData: event.target.value });
                }}>

                    <option value="">--Выберите Дату--</option>
                    {Datas.map((d) =>
                        <option value={`${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`}>{d.getDate()}/{d.getMonth() + 1}/{d.getFullYear()}</option>
                    )}
                </select>

                {err.planedData && (
                    <p style={{ color: "red" }}>{err.planedData}</p>
                )}
                <br></br>

                <label for="pet-select">Выберите Спецификацию:</label>

                <select value={newProd.spec.name} name="pets" id="pet-select" onChange={(event) => {
                    Test(event.target.value)
                }}>
                    <option value="">--Выберите Спецификацию--</option>
                    {spec.map((sp) => (
                        <option value={sp.name}>{sp.name}</option>

                    ))}
                </select>

                {err.spec && (
                    <p style={{ color: "red" }}>{err.spec}</p>
                )}
                <br></br>


                <label for="qty">Введите Планируемый выпуск {newProd.spec.newplanedqtymnt && (<span>{newProd.spec.newplanedqtymnt}</span>)}
                    {/* ({(spec.find(specname=>specname.name==newProd.spec_id).newplanedqtymnt)}): */}
                </label>
                <input value={newProd.plandeQty} type="number" id="qty" name="qty" onChange={(event) => {
                    setNewProd({ ...newProd, plandeQty: event.target.value });
                }} />
                {err.plandeQty && (
                    <p style={{ color: "red" }}>{err.plandeQty}</p>
                )}

                {newProd.spec && newProd.plandeQty && (
                    <div style={{ overflowX: 'auto' }}>
                        <table className={style.table}>
                            <thead className={style.tableth}>
                                <tr>
                                    <td className={style.tabletd}>№</td>
                                    <td className={style.tabletd}>Ресурс</td>
                                    <td className={style.tabletd}>Кол-во</td>
                                    <td className={style.tabletd}>Ед</td>
                                    {/* <td className={style.tabletd}>Действия</td> */}

                                </tr>

                            </thead>
                            <tbody>
                                {newProd.spec.usedResources.map((prod, index) => {
                                    // console.log(prod)
                                    const item = (JSON.parse(prod))
                                    return (<tr>
                                        <td className={style.tabletd}>{index + 1}</td>
                                        <td className={style.tabletd}>{item.name}</td>
                                        <td className={style.tabletd}>{item.qty / newProd.spec.planedqty * newProd.plandeQty}</td>
                                        <td className={style.tabletd}>{item.mnt}</td>
                                    </tr>)
                                }

                                )}
                            </tbody>
                        </table>
                    </div>
                )}



                <br></br>

                <Button text='Создать!'
                    onClick={create}
                />
            </form>
        </div>
    </>
    )
}
export default PlanedForm