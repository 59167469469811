import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewProdForm.module.css'
// import Title from "../../Title";
import Button from '../../../../../Button/Button'
import $api from "../../../../../../http/index.ts";
import axios from 'axios'
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
// import { FaEdit } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { BACKURL } from '../../../../../../conf.js'
import { BiListCheck } from 'react-icons/bi';
import Loading from 'react-fullscreen-loading';

const NewOptUserForm = () => {
    const { optUsers, setServerProd, setQuantidadeModalBtn, setProdutosSelecionados, admin, setOptUsers, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    const [loading1, setLoading1] = useState(false);

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])
    const [newAdres, setNewAdres] = useState({
        city: '',
        street: '',
        house: '',
        office: ''
    })
    const [newProd, setNewProd] = useState({
        name: "",
        login: "",
        pass: '',
        email: '',
        phone: '',
        org: '',
        inn: '',
        adress: []
    })
    const [err, setErr] = useState({})
    const [errAdres, seterrAdres] = useState({})
    const create = async (e) => {
        try {
            e.preventDefault();
            let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            let t = {
                name: false,
                login: false,
                pass: false,
                email: false,
                phone: false,
                org: false,
                inn: false,
            }

            if (!newProd.name) {
                t.name = 'ФИО обязательно к заполнению!'
            }
            if (!newProd.login) {
                t.login = 'Логин обязателен к заполнению!'
            }
            if (!newProd.pass) {
                t.pass = 'Пароль обязателен к заполнению!'
            }
            if (!newProd.email) {
                t.email = 'Пароль обязателен к заполнению!'
            }
            if (!mailformat.test(newProd.email)) {
                t.email = 'Адрес Электронной Почты неверный!'
                // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
            }
            if (!newProd.phone) {
                t.phone = 'Телефон обязателен к заполнению!'
            }
            if (!newProd.org) {
                t.org = 'Организация обязательна к заполнению!'
            }
            if (!newProd.inn) {
                t.inn = 'ИНН обязателен к заполнению!'
            }

            setErr(t)
            if (newProd.name &&
                newProd.login &&
                newProd.pass &&
                newProd.email &&
                newProd.phone &&
                newProd.org &&
                newProd.inn &&
                !t.name &&
                !t.login &&
                !t.pass &&
                !t.email &&
                !t.phone &&
                !t.qty &&
                !t.org &&
                !t.inn
            ) {

                setLoading1(true)


                const newProd5 = await $api.post(`${BACKURL}/api/utbteam/createoptuser`,
                    newProd
                )
                setOptUsers([...optUsers, newProd5.data])
                let prod = await axios.get(`${BACKURL}/api/getpruducts`)
                // console.log(prod.data)
                let prelist = []
                let prelistDry = []
                let history = JSON.parse(localStorage.getItem("petNew"));
                let produtosSelecionadosS = JSON.parse(localStorage.getItem("produtosSelecionados"));
                let quantidadeModalBtnS = JSON.parse(localStorage.getItem("quantidadeModalBtn"));
                // console.log(history, produtosSelecionadosS, quantidadeModalBtnS)

                for (let i = 0; i < prod.data.length; i++) {
                    // console.log(prod.data[i].kategory)
                    // console.log(prod.data[i])shcode
                    prelist[i] = ({
                        shcode: prod.data[i].shcode,
                        kategory: prod.data[i].kategory,
                        name: prod.data[i].name,
                        brakqty: prod.data[i].brakqty,

                        mnt: prod.data[i].mnt,
                        price: Number(prod.data[i].price),
                        optprice: Number(prod.data[i].optprice),
                        id: Number(prod.data[i].id),
                        quantidade: 1,
                        vipprice: prod.data[i].vipprice.map(pf => JSON.parse(pf)),
                        // planedqty:prod.data[i].planedqty,
                        photo: prod.data[i].photo,
                        text: prod.data[i].about,
                        recomendation: prod.data[i].recomendation,
                        scladqty: prod.data[i].scladqty,
                    })
                }
                if (!history) {
                    prelist = history
                }
                if (produtosSelecionadosS != null) {
                    setProdutosSelecionados(produtosSelecionadosS)
                }
                if (!quantidadeModalBtnS != null) {
                    setQuantidadeModalBtn(quantidadeModalBtnS)
                }
                // console.log(prelist)
                setSclad(prelist)
                setServerProd(prelist);
                setNewProd({
                    name: "",
                    login: "",
                    pass: '',
                    email: '',
                    phone: '',
                    org: '',
                    inn: '',
                    adress: []

                })
                setLoading1(false)

                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            console.log(e)

            setLoading1(false)

        }

    }
    const deleteFromList = (index) => {
        // e.preventDefault();
        let newlist = []
        for(let i = 0 ; i<newProd.adress.length;i++ ){
            if(i!=index){
                newlist.push(newProd.adress[i])
            }
        }
        // const newList = newProd.adress.splice(index,1)
        setNewProd({
            name: newProd.name,
            login: newProd.login,
            pass: newProd.pass,
            email: newProd.email,
            phone: newProd.phone,
            org: newProd.org,
            inn: newProd.inn,
            adress: newlist
        })
    }
    const addAdres = async (e) => {
        e.preventDefault();

        let t = {
            city: false,
            street: false,
            house: false,
            office: false,
        }

        if (!newAdres.city) {
            t.city = 'Город обязателен для заполнения'
        }
        if (!newAdres.street) {
            t.street = 'Улица обязателена для заполнения'
        }
        if (!newAdres.house) {
            t.house = 'Подьез обязателен для заполнения'
        }
        if (!newAdres.office) {
            t.office = 'Офис обязателен для заполнения'
        }
        seterrAdres(t)

        if (
            newAdres.city &&
            newAdres.street &&
            newAdres.house &&
            newAdres.office &&
            !t.city &&
            !t.house &&
            !t.office &&
            !t.street
        ) {

            const adresses = [...newProd.adress, newAdres]
            setNewProd({
                name: newProd.name,
                login: newProd.login,
                pass: newProd.pass,
                email: newProd.email,
                phone: newProd.phone,
                org: newProd.org,
                inn: newProd.inn,
                adress: adresses
            })
            console.log(adresses)
            setNewAdres({
                city: '',
                street: '',
                house: '',
                office: ''
            })
        }

    }

    return (<>
        <br />
        <div className={style.container_form}>
            <form className={style.form}>
                <label for="name">Введите ФИО:</label>
                <input value={newProd.name} type="text" id="name" name="name" onChange={(event) => {
                    setNewProd({ ...newProd, name: event.target.value });
                }} />
                {err.name && (
                    <p style={{ color: "red" }}>{err.name}</p>
                )}
                <br></br>
                <label for="login">Введите логин:</label>
                <input value={newProd.login} type="text" id="name" name="login" onChange={(event) => {
                    setNewProd({ ...newProd, login: event.target.value.toLowerCase() });
                }} />
                {err.login && (
                    <p style={{ color: "red" }}>{err.login}</p>
                )}
                <br></br>

                <label for="pass">Введите пароль:</label>
                <input value={newProd.pass} type="text" id="pass" name="pass" onChange={(event) => {
                    setNewProd({ ...newProd, pass: event.target.value });
                }}
                />
                {err.pass && (
                    <p style={{ color: "red" }}>{err.pass}</p>
                )}
                <br></br>

                <label for="email">Введите email:</label>
                <input value={newProd.email} type="text" id="email" name="email" onChange={(event) => {
                    setNewProd({ ...newProd, email: event.target.value });
                }} />
                {err.email && (
                    <p style={{ color: "red" }}>{err.email}</p>
                )}
                <br></br>

                <label for="phone">Введите номер телефона:</label>
                <input value={newProd.phone} type="text" id="phone" name="phone" onChange={(event) => {
                    setNewProd({ ...newProd, phone: event.target.value });
                }} />
                {err.phone && (
                    <p style={{ color: "red" }}>{err.phone}</p>
                )}
                <br></br>

                <label for="org">Введите название организации:</label>
                <input value={newProd.org} type="text" id="org" name="org" onChange={(event) => {
                    setNewProd({ ...newProd, org: event.target.value });
                }} />
                {err.org && (
                    <p style={{ color: "red" }}>{err.org}</p>
                )}
                <br></br>

                <label for="inn">Введите ИНН:</label>
                <input value={newProd.inn} type="text" id="inn" name="inn" onChange={(event) => {
                    setNewProd({ ...newProd, inn: event.target.value });
                }} />
                {err.inn && (
                    <p style={{ color: "red" }}>{err.inn}</p>
                )}
                <br></br>
                {/* ////////// */}
                <div className={style.border}>
                <br />      
                <div style={{ overflowX: 'auto' }}>

            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>Город</td>
                        <td className={style.tabletd}>Улица</td>
                        <td className={style.tabletd}>Подьезд</td>
                        <td className={style.tabletd}>Офис</td>
                        <td className={style.tabletd}>Удалить</td>
                       

                    </tr>
                </thead>
                <tbody>
                        {newProd.adress.map((pr, index) => (<>
                            <tr>
                              <td className={style.tabletd}>{index + 1}</td>
                              <td className={style.tabletd}>{pr.city}</td>
                              <td className={style.tabletd}>{pr.street}</td>
                              <td className={style.tabletd}>{pr.house}</td>
                              <td className={style.tabletd}>{pr.office}</td>
                              <td className={style.tabletd}>
                                                <div className={style.cell}><>
                                                    <FcCancel className={style.icon} onClick={() => deleteFromList(index)} />
                                                </> </div>
                                            </td>
                                            </tr>
                     </>   ))}
                   </tbody>
            </table>
            </div>
                    <label for="city">Введите город:</label>
                    <input value={newAdres.city} type="text" id="city" name="city" onChange={(event) => {
                        setNewAdres({ ...newAdres, city: event.target.value });
                    }}
                    />
                    {errAdres.city && (
                        <p style={{ color: "red" }}>{errAdres.city}</p>
                    )}
                    <br></br>
                    <label for="street">Введите улицу:</label>
                    <input value={newAdres.street} type="text" id="street" name="street" onChange={(event) => {
                        setNewAdres({ ...newAdres, street: event.target.value });
                    }}
                    />
                    {errAdres.street && (
                        <p style={{ color: "red" }}>{errAdres.street}</p>
                    )}
                    <br></br>
                    <label for="house">Введите подьезд:</label>
                    <input value={newAdres.house} type="text" id="house" name="house" onChange={(event) => {
                        setNewAdres({ ...newAdres, house: event.target.value });
                    }}
                    />
                    {errAdres.house && (
                        <p style={{ color: "red" }}>{errAdres.house}</p>
                    )}
                    <br></br>
                    <label for="office">Введите номер офиса:</label>
                    <input value={newAdres.office} type="text" id="office" name="office" onChange={(event) => {
                        setNewAdres({ ...newAdres, office: event.target.value });
                    }}
                    />
                    {errAdres.office && (
                        <p style={{ color: "red" }}>{errAdres.office}</p>
                    )}
                    <br></br>


                    <Button text='Добавить адрес!' onClick={addAdres} />






                </div>
                <br></br>
                {!loading1 && (
                    <Button text='Создать!' onClick={create} />

                )}
                {loading1 && (
                    <Button text='Создаем...' disabled={true} />

                )}
            </form>
        </div>
        {loading1&&(
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
    </>
    )
}
export default NewOptUserForm