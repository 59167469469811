import React, { useContext, useEffect, useState } from "react";
import { QtdContext } from "../../../context/qtdContext";
import style from "./ButtonsModal.module.css";
// import { produtos } from "../../Produtos/JsonProdutos";
// const ButtonsModal = ({ id, cartProdutoId }) => {
//   const produtos = JSON.parse(localStorage.getItem("products"));

//   const {  quantidadeModalBtn, setQuantidadeModalBtn } = useContext(QtdContext);
//   const produtoQtd = produtos.find((produto) => produto.id == id);
//   const produtoCartQtd = produtos.find(
//     (produto) => produto.id == cartProdutoId
//   );
// console.log(produtoQtd)
//   const getQuantidade = produtoCartQtd
//     ? produtoCartQtd.quantidade
//     : produtoQtd.quantidade;

//   console.log(getQuantidade, id, cartProdutoId)

//   // const addItemCart = () =>
//   // produtoCartQtd
//   //   ? setQuantidadeModalBtn(produtoCartQtd.quantidade + 1)

//   //   : setQuantidadeModalBtn(produtoQtd.quantidade + 1);

//   const addItemCart = () =>
//     produtoCartQtd
//       ? setQuantidadeModalBtn(
//         (produtoCartQtd.quantidade = produtoCartQtd.quantidade + 1)
//       )
//       : setQuantidadeModalBtn(
//         (produtoQtd.quantidade = produtoQtd.quantidade + 1)
//       );

//   const removeItemCart = () => {
//     console.log(getQuantidade)
//     if (produtoCartQtd && produtoCartQtd.quantidade > 1) {
//       setQuantidadeModalBtn(
//         (produtoCartQtd.quantidade = produtoCartQtd.quantidade - 1)
//       )
//     } else {
//       if (produtoQtd && produtoQtd.quantidade > 1) {
//         setQuantidadeModalBtn(
//           (produtoQtd.quantidade = produtoQtd.quantidade - 1)
//         );
//       }

//     }
//   }


//   return (
//     <div className={style.select_qtd}>
//       <button onClick={addItemCart}>+</button>
//       <p className={style.qtd}>{getQuantidade} </p>
//       <button onClick={removeItemCart}>-</button>
//     </div>
//   );
// }
//   ;

const ButtonsModal = ({ id, cartProdutoId }) => {
  const produtos = JSON.parse(localStorage.getItem("petNew"));
  const { serverProd, opt, setServerProd, setQuantidadeModalBtn, setProdutosSelecionados } = useContext(QtdContext);
  const produtoQtd = serverProd.find((produto) => produto.id === id);
  const produtoCartQtd = produtos.find(
    (produto) => produto.id === cartProdutoId
  );
  // console.log(produtoQtd, produtoCartQtd, id)
  const getQuantidade = produtoCartQtd
    ? produtoCartQtd.quantidade
    : produtoQtd.quantidade;;

  const addItemCart = async () => {

    if (produtoCartQtd && produtoCartQtd.quantidade + 1 <= produtoCartQtd.scladqty) {
      setQuantidadeModalBtn((produtoCartQtd.quantidade = produtoCartQtd.quantidade + 1))
      const newPosts = produtos.map((post) => (
        post.id == id
          ? { ...post, quantidade: produtoCartQtd.quantidade }
          : post
      ));
      setProdutosSelecionados(newPosts)
      localStorage.setItem('localStorageInitTime', +new Date());

      localStorage.setItem("produtosSelecionados", JSON.stringify(newPosts));
      localStorage.setItem("petNew", JSON.stringify(newPosts));
      localStorage.setItem("quantidadeModalBtn", JSON.stringify(produtoCartQtd.quantidade));
      // setServerProd(newPosts)
    } else if (produtoQtd && produtoQtd.quantidade + 1 <= produtoQtd.scladqty) {

      setQuantidadeModalBtn(
        (produtoQtd.quantidade = produtoQtd.quantidade + 1)
      );
      const newPosts = serverProd.map((post) => (
        post.id == id
          ? { ...post, quantidade: produtoQtd.quantidade }
          : post
      ));
      // localStorage.setItem("petNew", JSON.stringify(newPosts));
      // localStorage.setItem("quantidadeModalBtn", JSON.stringify(produtoCartQtd.quantidade));
      setServerProd(newPosts)
    }
  }



  const removeItemCart = () => {
    if (produtoCartQtd && produtoCartQtd.quantidade > 1) {

      setQuantidadeModalBtn(
        (produtoCartQtd.quantidade = produtoCartQtd.quantidade - 1)
      )
      const newPosts = produtos.map((post) => (
        post.id == id
          ? { ...post, quantidade: produtoCartQtd.quantidade }
          : post
      ));
      setProdutosSelecionados(newPosts)
      localStorage.setItem('localStorageInitTime', +new Date());

      localStorage.setItem("produtosSelecionados", JSON.stringify(newPosts));
      localStorage.setItem("petNew", JSON.stringify(newPosts));
      localStorage.setItem("quantidadeModalBtn", JSON.stringify(produtoCartQtd.quantidade));
      // setServerProd(newPosts)
    } else {
      if (produtoQtd && produtoQtd.quantidade > 1) {
        setQuantidadeModalBtn(
          (produtoQtd.quantidade = produtoQtd.quantidade - 1)
        );
        const newPosts = serverProd.map((post) => (
          post.id == id
            ? { ...post, quantidade: produtoQtd.quantidade }
            : post
        ));
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        // localStorage.setItem("quantidadeModalBtn", JSON.stringify(produtoCartQtd.quantidade));
        setServerProd(newPosts)
      }

    }
  }

  return (
    <div className={style.select_qtd}>
      <button onClick={removeItemCart}>-</button>
      <p className={style.qtd}>{'  '}{getQuantidade}{'  '}</p>
      <button onClick={addItemCart}>+</button>
    </div>
  );
}
  ;

// export default ButtonsModal;

export default ButtonsModal;