import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewProdForm.module.css'
// import Title from "../../Title";
import Button from '../../../../../../Button/Button'
import $api from "../../../../../../../http/index.ts";

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
// import { FaEdit } from "react-icons/fa";
// import ModalEdit from '../Components/Modal/Modal';
import {BACKURL} from '../../../../../../../conf.js'
const AddRess = () => {
    const { sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    const [newProd, setNewProd] = useState({
        name: "",
        kategory: '',
        qty: '',
        mnt:''
    })
    const [err, setErr] = useState({})
    const create = async (e) => {
        try {
            e.preventDefault();

            let t = {
                name: false,
                kategory: false,
                mnt: false,
            }

            if (!newProd.name) {
                t.name = 'Наименование обязательно к заполнению!'
            }
            if (!newProd.kategory) {
                t.kategory = 'Категория обязательна к выбору!'
            }
          
            if (!newProd.mnt) {
                t.mnt = 'Розничная цена обязательна к заполнению!'
            }
           

            setErr(t)
            if (newProd.name &&
                newProd.kategory &&
                newProd.mnt &&
            
                !t.name &&
                !t.kategory &&
                !t.mnt
              
            ) {



                const newProd5 = await $api.post(`${BACKURL}/api/factory/createfactoryproduct`,
                    newProd
                )
                setSclad(newProd5.data)
                setNewProd({})
                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            // console.log(e)
        }

    }


    return (<>
        <br />
        <div className={style.container_form}>
            <form className={style.form}>
                <label for="name">Введите Наименование:</label>
                <input value={newProd.name} type="text" id="name" name="name" onChange={(event) => {
                    setNewProd({ ...newProd, name: event.target.value });
                }} />
                {err.name && (
                    <p style={{ color: "red" }}>{err.name}</p>
                )}
                <br></br>
                <label for="pet-select">Выберите Категорию:</label>

                <select value={newProd.kategory} name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, kategory: event.target.value });
                }}>
                    <option value="">--Выберите Категорию--</option>
                    <option value="Производство - Специи">Специи</option>
                    <option value="Производство - Мясо">Мясо</option>
                    <option value="Производство - Прочее">Прочее</option>
                </select>

                {err.kategory && (
                    <p style={{ color: "red" }}>{err.kategory}</p>
                )}
                <br></br>
                <label for="pet-select">Выберите Ед. Измерения:</label>

                <select value={newProd.mnt} name="pets" id="pet-select" onChange={(event) => {
                    setNewProd({ ...newProd, mnt: event.target.value });
                }}>
                    <option value="">--Выберите Ед. Измерения--</option>
                    <option value="кг">кг</option>
                    <option value="л">л</option>
                    <option value="гр">гр</option>
                    <option value="шт">шт</option>
                </select>

                {err.mnt && (
                    <p style={{ color: "red" }}>{err.mnt}</p>
                )}
                <br></br>

             

                <br></br>

                <Button text='Создать!' onClick={create} />
            </form>
        </div>
    </>
    )
}
export default AddRess