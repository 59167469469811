import React, { useContext, useEffect, useState } from "react";
import { images } from "./JsonImages";
import style from "./Cards.module.css";
import img5 from '../Assets/CardsImages/5.png';
import img6 from '../Assets/CardsImages/6.png';
import img1 from '../Assets/CardsImages/1.png';
import img12 from '../Assets/CardsImages/12.png';
import img2 from '../Assets/CardsImages/suh.png';
import farsh from '../Assets/CardsImages/farsh.png';
import yhod from '../Assets/CardsImages/3634685.png';
import amun from '../Assets/CardsImages/6462561.png';
import { useNavigate, useLocation } from "react-router-dom";
import { QtdContext } from "../../context/qtdContext";


const Cards = () => {

  const navigate = useNavigate();
  const { menu, setMenu } = useContext(QtdContext);

  return (
    <div className={style.cards}>

      <div key={'Влажный Корм'} className={style.card} onClick={() => {
        navigate('/cuns');
        setMenu(false)
      }}>
        <img className={style.imgcard} src={img6} />
        <p className={style.text_card}>{'Влажный Корм'}</p>
      </div>

      <div key={'Лакомства'} className={style.card} onClick={() => {
        navigate('/lacomstva')
        setMenu(false)
      }}>
        <img className={style.imgcard} src={img5} />
        <p className={style.text_card}>{'Лакомства'}</p>
      </div>

      <div key={'Рационы'} className={style.card} onClick={() => {
        navigate('/racions')
        setMenu(false)
      }}>
        <img className={style.imgcard} src={img12} />
        <p className={style.text_card}>{'Рационы'}</p>
      </div>
      <div key={'Заморозка'} className={style.card} onClick={() => {
        navigate('/zamorozka')
        setMenu(false)
      }}>
        <img className={style.imgcard} src={img1} />
        <p className={style.text_card}>{'Заморозка'}</p>
      </div>
      {/* <div key={'Сухой Корм'} className={style.card} onClick={()=> {navigate('/suhkorm')
            setMenu(false)
        }}>
          <img className={style.imgcard} src={img2} />
          <p className={style.text_card}>{'Сухой Корм'}</p>
        </div> */}
      <div key={'Фаршики'} className={style.card} onClick={() => {
        navigate('/farsh')
        setMenu(false)
      }}>
        <img style={{ width: "80px" }} className={style.imgcard} src={farsh} />
        <p className={style.text_card}>{'Фаршики'}</p>
      </div>
      {/* <div key={'Уход'} className={style.card} onClick={()=> navigate('/care')}>
          <img className={style.imgcard} src={yhod} />
          <p className={style.text_card}>{'Уход'}</p>
        </div>
        <div key={'Амуниция'} className={style.card} onClick={()=> navigate('/amunation')}>
          <img className={style.imgcard} src={amun} />
          <p className={style.text_card}>{'Амуниция'}</p>
        </div> */}

    </div>
  );
};

export default Cards;
