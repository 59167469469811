import React, { useEffect, useContext, useState } from "react";
import { produtos } from "./JsonProdutosDry";
import style from "./Produtos.module.css";
import Modal from "../Modal/Modal";
import { QtdContext } from "../../context/qtdContext";
import { useNavigate } from "react-router-dom";
import Title from '../Title'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ButtonsModal from "../Modal/ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
const MorozData = ({ amount }) => {
  // const “ = JSON.parse(localStorage.getItem("products"));
  const { serverProd, produtosSelecionados, opt, tabs, setTabs, setModalCart, setProdutosSelecionados } = useContext(QtdContext);
  const navigate = useNavigate()
  // const drydey = serverProd.filter((prod) => prod.kategory == "рацион")
  // console.log(drydey)
  const drydey2 = serverProd.filter((prod) => prod.kategory == "отдельная мороженная продукция"&&prod.showed).sort((a, b) => {
    // Сначала сортируем по условию scladqty > 0
    if (a.scladqty > 0 && b.scladqty === 0) return -1;
    if (a.scladqty === 0 && b.scladqty > 0) return 1;
    
    // Если оба объекта в одной группе, сортируем по name
    return a.name.localeCompare(b.name);
  });

  const GoInfo = (imageId, name) => {
    console.log('gogogo')
    navigate(`/prodinfo/${imageId}`, {
      state: {
        serverProd:serverProd,

        produtosSelecionados:produtosSelecionados,
        name: name,
        imageId: imageId,
        loca: "/zamorozka"
      }
    });
  };
  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const changeItensCart = (id) => {
    const produtos = serverProd

    const produtoFoiSelecionado = produtosSelecionados.find((produto) => produto.id == +id)
    const produtoDados = produtos.find((produto) => produto.id == +id);



    const produto = produtoFoiSelecionado ? produtoFoiSelecionado : produtoDados
    const produtosNaoSelecionados = produtosSelecionados.filter((item) => item.id !== +id);
    const novosProdutos = [...produtosNaoSelecionados, produto].sort((a, b) => a.id - b.id);
    // console.log(novosProdutos, produto)
    setProdutosSelecionados(novosProdutos);
    localStorage.setItem("produtosSelecionados", JSON.stringify(novosProdutos));

    // console.log(produtosNaoSelecionados, produtosSelecionados)
    // setModal(false);
    // console.log('novosProdutos')
  }
  // let tt = photo[0]
  // if(!photo){
  //   tt = ''
  // }

  return (
    <>
       <br />
 <div className={style.borders}>
      <br />
      <p className={style.p}>
      Также представляем вашему вниманию линейку натуральных свежемороженных продуктов торговой марки КотоPes — сбалансированное и здоровое питание для ежедневного кормления ваших домашних питомцев. Изготовлено из 100% натуральных продуктов, используемых в питании человека (свежие ингредиенты категории Human Grade – человеческий стандарт качества).</p>
      <br />
      <p className={style.p}>
      Важно: рационы не содержат консерванты, усилители вкуса, красители, ароматизаторы и зерновые культуры. ТОЛЬКО НАТУРАЛЬНЫЕ МЯСНЫЕ ИНГРЕДИЕНТЫ И ОВОЩИ!      </p>
      <br />
   </div>
   
      <Title text="Отдельная Продукция" />
      <br />
      <div className={style.produtos_container}>
      {!opt.auth && drydey2.map(
          ({ photo, name, price,vipprice,forwho, id,scladqty }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
               const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
            return index <= amount && (
              <div key={id} id={id} className={style.cards_produtos} style={{opacity:scladqty==0?"0.5":"1"}}>
                {/* <img id={id} className={style.img} src={tt} /> */}
               <div onClick={() => GoInfo(id, name)} className={style.imgcont} style={{ height: '250px' }}>
                     <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt="Example Image"
                      height="100%"
                      effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                    />
                </div>
                <p onClick={() => GoInfo(id, name)}id={id}>{name}</p>
                {/* <p id={id} className={style.price}><b>{price}RUB</b></p> */}
                <div onClick={() => GoInfo(id, name)}className={style.vbadgecont}>
                    {forwho == 'Для собак' && (
                      <div href="#" className={style.vbadge}style={{ boxShadow:'none',fontSize:'50px', border:'none','background':'none', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        🐶<span></span>
                      </div>
                    )}
                    {forwho == 'Для кошек' && (
                      <div href="#" className={style.vbadge} style={{ boxShadow:'none',fontSize:'50px', border:'none','background':'none', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        🐱<span></span>
                      </div>
                    )}
                    {name == 'Мясные консервы «говядина с потрошками» 525г' && (
                      <div href="#" className={style.vbadge} style={{ height:'30px','background': '#04b31b', 'color': 'black', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        ХИТ
                        <span></span>
                      </div>
                    )}
                    {Number(scladqty) > 0 &&
                      <div href="#" className={style.vbadge} style={{height:'30px', 'background': 'var(--primary-color)', 'color': 'black', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        {price}RUB
                        <span></span>
                      </div>}
                    {Number(scladqty) <= 0 &&
                      <div href="#" className={style.vbadge} style={{height:'30px', 'background': 'var(--primary-color)', 'color': 'black', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        Распродано!
                        <span></span>
                      </div>}

                    <div href="#" className={style.vbadge} style={{height:'30px', 'background': '#20b6e8', 'color': 'black', display:'flex', alignItems:'center', justifyContent:'center' }}>
                      Натуральный
                      <span></span>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb &&Number(scladqty) > 0 &&  (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb &&Number(scladqty) > 0 &&  (<>
                        <Button  width={'100px'}text="В Корзину" style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button  width={'100px'}text="Удалить из Корзины " style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
              </div>
            )
          }
        )}
        {opt.auth && drydey2.map(
          ({ photo, name, optprice,vipprice, id,scladqty }, index) => {
            let tt = ''
            if (photo[0]) {
              tt = photo[0]
            }
               const cart = produtosSelecionados.find((produto) => produto.id == +id);
                let cartb = false
                if (cart) {
                  cartb = true
                }
            return index <= amount && (
              <div key={id} id={id}   className={style.cards_produtos}>
                {/* <img id={id} className={style.img} src={tt} /> */}
               <div onClick={() => GoInfo(id, name)}className={style.imgcont}>
                     <LazyLoadImage
                      id={id}
                      className={style.img}
                      src={tt}
                      alt="Example Image"
                      height="100%"
                      effect="blur"
                      // src="http://localhost:3000/static/images/example.jpg" // Замените на ваш путь к изображению
                      // width="600px"
                    />
                </div>
                <p onClick={() => GoInfo(id, name)}id={id}>{name}</p>
                <p onClick={() => GoInfo(id, name)}id={id} className={style.price}><b>{optprice}RUB</b></p>
                <div onClick={() => GoInfo(id, name)}className={style.vbadgecont}>
                  <div href="#" className={style.vbadge}>
                    NEW
                    <span></span>
                  </div>
                  {vipprice.find((vp) => vp.optuserid == opt.user.id) && (vipprice.find((vp) => vp.optuserid == opt.user.id)).vipprice > 0 ? (
                      <div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                        {console.log((vipprice.find((vp) => vp.optuserid == opt.user.id)))}
                        {(vipprice.find((vp) => vp.optuserid == opt.user.id)
                        ).vipprice}RUB
                        <span></span>
                      </div>
                    ) :

                      (<div href="#" className={style.vbadge} style={{ 'background': 'var(--primary-color)', 'color': 'black' }}>
                        {optprice}RUB
                        <span></span>
                      </div>)
                    }

                  <div href="#" className={style.vbadge} style={{ 'background': '#20b6e8' }}>
                    Натуральный
                    <span></span>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }} className={style.container_price}>
                      {!cartb &&Number(scladqty) > 0 &&  (<>


                        <ButtonsModal id={id} />
                        <Button style={{ height: '100%', margin: 0, marginLeft: '5px' }} text="Добавить В Корзину" onClick={() => changeItensCart(id)} />
                      </>
                      )}
                      {cartb &&Number(scladqty) > 0 &&  (<>
                        <Button  width={'100px'}text="В Корзину" style={{ 'width': '100px' }} onClick={() => setModalCart(true)} />
                        <Button  width={'100px'}text="Удалить из Корзины " style={{ 'width': '130px' }} onClick={() => removeItem(id)} />
                      </>
                      )}
                    </div>
              </div>
            )
          }
        )}
      </div>
    </>
  );
};

export default MorozData;
