import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import {BACKURL} from '../../../../../conf.js'
const ModalEditOptUser = ({ thisChange, setThisChange, prod }) => {

  const navigate = useNavigate();
  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { serverProd,setOptUsers, optUsers, setServerProd, modalEditOptUser, setModalEditOptUser,admin } = useContext(QtdContext);
 
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container127" && setModalEditOptUser(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };


  const editLogin = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptlogin/${thisChange.prod.id}`,
      { login: thisChange.loginValue,admin:admin.user }
    )
    const newPosts = optUsers.map((post) => (
      post.id == thisChange.prod.id
        ? { ...post, login: newProd.data.login }
        : post
    ));
    // console.log(thisChange)
    setOptUsers(newPosts)
    alert('Логин успешно обновлен в системе!')
    setModalEditOptUser(false)
  }
  const editPass = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptpass/${thisChange.prod.id}`,
      { pass: thisChange.passValue,admin:admin.user }
    )
    const newPosts = optUsers.map((post) => (
      post.id == thisChange.prod.id
        ? { ...post, pass: newProd.data.pass }
        : post
    ));
    setOptUsers(newPosts)

    alert('Пароль успешно обновлен в системе!')
    setModalEditOptUser(false)
  }
  const editEmail = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptemail/${thisChange.prod.id}`,
      { email: thisChange.emailValue,admin:admin.user }
    )
    const newPosts = optUsers.map((post) => (
      post.id == thisChange.prod.id
        ? { ...post, email: newProd.data.email }
        : post
    ));
    setOptUsers(newPosts)

    alert('email успешно обновлен в системе!')
    setModalEditOptUser(false)
  }
  const editAdress = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptadress/${thisChange.prod.id}`,
      { adress: thisChange.adressValue,admin:admin.user }
    )
    const newPosts = optUsers.map((post) => (
      post.id == thisChange.prod.id
        ? { ...post, adress: newProd.data.adress }
        : post
    ));
    setOptUsers(newPosts)

    alert('adress успешно обновлен в системе!')
    setModalEditOptUser(false)
  }
  const editOrg = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptorg/${thisChange.prod.id}`,
      { org: thisChange.orgValue,admin:admin.user }
    )
    const newPosts = optUsers.map((post) => (
      post.id == thisChange.prod.id
        ? { ...post, org: newProd.data.org }
        : post
    ));
    setOptUsers(newPosts)

    alert('Организация успешно обновлена в системе!')
    setModalEditOptUser(false)
  }
  const editInn = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptinn/${thisChange.prod.id}`,
      { inn: thisChange.innValue,admin:admin.user }
    )
    const newPosts = optUsers.map((post) => (
      post.id == thisChange.prod.id
        ? { ...post, inn: newProd.data.inn }
        : post
    ));
    setOptUsers(newPosts)

    alert('ИНН успешно обновлен в системе!')
    setModalEditOptUser(false)
  }
  const editPhone = async () => {
    const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptphone/${thisChange.prod.id}`,
      { phone: thisChange.phoneValue,admin:admin.user }
    )
    const newPosts = optUsers.map((post) => (
      post.id == thisChange.prod.id
        ? { ...post, phone: newProd.data.phone }
        : post
    ));
    setOptUsers(newPosts)

    alert('Телефон успешно обновлен в системе!')
    setModalEditOptUser(false)
  }


  return (
    <>
      {modalEditOptUser && (
        <div onClick={closeModal} id="container127" className={style.modal_container}>
          {/* <div className={style.container_modal}> */}

          {/* TEST */}
          {thisChange.login && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.login}`}></Title>
              </div>
              <form className={style.form}>
                <label for="логин">Введите новый логин:</label>
                <input type="text" id="логин" name="логин" onChange={(event) => {
                  setThisChange({ ...thisChange, loginValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить логин"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editLogin}
              />
            </div>
          )}
          {thisChange.pass && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.pass}`}></Title>
              </div>
              <form className={style.form}>
                <label for="pass">Введите новый пароль:</label>
                <input type="text" id="pass" name="pass" onChange={(event) => {
                  setThisChange({ ...thisChange, passValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить пароль"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editPass}
              />
            </div>
          )}
          {thisChange.email && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.email}`}></Title>
              </div>
              <form className={style.form}>
                <label for="email">Введите новый email:</label>
                <input type="text" id="email" name="email" onChange={(event) => {
                  setThisChange({ ...thisChange, emailValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить email"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editEmail}
              />
            </div>
          )}
          {thisChange.adress && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.adress}`}></Title>
              </div>
              <form className={style.form}>
                <label for="adress">Введите новый адрес:</label>
                <input type="text" id="adress" name="adress" onChange={(event) => {
                  setThisChange({ ...thisChange, adressValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить адрес"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editAdress}
              />
            </div>
          )}
          {thisChange.org && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.org}`}></Title>
              </div>
              <form className={style.form}>
                <label for="орг">Введите новую организацию:</label>
                <input type="text" id="орг" name="орг" onChange={(event) => {
                  setThisChange({ ...thisChange, orgValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить цену"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editOrg}
              />
            </div>
          )}
          {thisChange.inn && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.inn}`}></Title>
              </div>
              <form className={style.form}>
                <label for="inn">Введите новый ИНН:</label>
                <input type="text" id="inn" name="inn" onChange={(event) => {
                  setThisChange({ ...thisChange, innValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}

              </form>
              <Button
                text="Изменить ИНН"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editInn}
              />
            </div>
          )}
            {thisChange.phone && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.phone}`}></Title>
              </div>
              <form className={style.form}>
                <label for="phone">Введите новый номер телефона:</label>
                <input type="text" id="phone" name="phone" onChange={(event) => {
                  setThisChange({ ...thisChange, phoneValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              <Button
                text="Изменить телефон"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={editPhone}
              />
            </div>
          )}
         


        </div>

        // </div>
      )}

    </>
  );
};

export default ModalEditOptUser;
