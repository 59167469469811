import React, { useContext, useEffect, useState } from "react";
import style from "./MenuMobile.module.css";
import LinksMenu from "../LinksMenu";
import Logo from "../../Logo/Logo";
import Cart from "../../Cart/Cart";
import { NavLink, useLocation } from "react-router-dom";
import LinksMenuMobile from "../LinksMenuMobile";
import { QtdContext } from "../../../context/qtdContext";

const MenuMobile = () => {
  const { menu, setMenu } = useContext(QtdContext);

  // const [checkbox, setCheckbox] = useState(false);
  const location = useLocation()
  let test = true
  if ((location.pathname == "/createorder" || location.pathname == "/success")) {
    test = false
  }
  const closeModal = ({ target }) => {
    target.tagName === 'A' && setMenu(false)
  }

  return (
    <div className={style.main_container}>



      <input
        type="checkbox"
        checked={menu}
        // onChange={({ target }) => setMenu(target.checked)}
        id={style.active}
      />
      <div className={style.container_menu}>

        <label style={{height:'50px'}}for={style.active} onClick={()=>setMenu(!menu)} className={style.menu_btn}>
          {/* {menu && ( */}
            <div style={{marginTop: '20px',
justifyContent: 'center', display:'flex'}} className={style.menu} >

              <div id={style.bar} className={menu && style.animate}>

              </div>

            </div>
          {/* )} */}


        </label>
        {test && (
          <Cart />)}
      </div>
      {menu && (

        <div onClick={closeModal} className={style.wrapper}>
          <LinksMenuMobile />
        </div>
      )}

    </div>
  );
};

export default MenuMobile;