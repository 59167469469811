import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
import $api from "../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../Modal/Modal';
import { FcPlus } from "react-icons/fc";

import { confirmAlert } from 'react-confirm-alert'; // Import
import '../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../conf.js'
import Modalpromo from './Modalpromo';
import { Toggle } from './togle';
const PromoCodesComponent = () => {
    const { modalPromo, setModalPromo, PromoCodes, setPromoCodes, admin, sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    const [thisChange, setThisChange] = useState({})

    const edit = async (promo, code, sale) => {
        setThisChange({
            promo: promo,
            code: code,
            codeValue: '',
            sale: sale,
            saleValue: '',

        })
        setModalPromo(true)

    }


    const deleteProd = async (id) => {
        const DeletedId = await $api.get(`${BACKURL}/api/utbteam/promocodes/deletepromo/${id}`)
        const newpromo = PromoCodes.filter((post) => post.id != id)
        setPromoCodes(newpromo)
        alert('код удален!')
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить промокод?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };




    const addPromo = async (data) => {

        const promo = await $api.get(`${BACKURL}/api/utbteam/promocodes/create`
        )


        setPromoCodes([...PromoCodes, promo.data])

        alert('Готово!')
    }
    const updatestatus = async (data) => {

        const DeletedId = await $api.post(`${BACKURL}/api/utbteam/promocodes/updatestatus/${data.id}`,
            { status: data.status, admin: admin.user }
        )
        const newPosts = PromoCodes.map((post) => (
            post.id == data.id
                ? { ...post, status: data.status }
                : post
        ));

        setPromoCodes(newPosts)

        alert('Готово!')
    }

    const submitStatus = async (func, data) => {
        let text = 'активировать'
        if (data.status == "off") {
            text = 'деактивировать'
        }
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: `Вы уверены, в том, что хотите ${text} промокод?`,
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };

    return (<>
        <div style={{ overflowX: 'auto' }}>
        {admin.role == 'GOD' && (
<>
                <FcPlus onClick={() => { addPromo() }} className={style.icon} />

            </>)}



            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>Код</td>
                        <td className={style.tabletd}>Использован</td>
                        <td className={style.tabletd}>Скидка</td>
                        <td className={style.tabletd}>on/off</td>
                        <td className={style.tabletd}>Дата изменения</td>
                        {admin.role == 'GOD' && (
<>
                            <td className={style.tabletd}>Удалить</td>
                        </>)}


                    </tr>
                </thead>
                <tbody>
                    {PromoCodes.map(
                        (promo, index) => <>
                            <tr>
                                <td className={style.tabletd}>
                                {admin.role == 'GOD' && (
<>
                                        <FaEdit onClick={() => { edit(promo, true, false, false) }} className={style.icon} />

                                    </>)}
                                    {promo.code}</td>
                                <td className={style.tabletd}>{promo.counter}</td>

                                <td className={style.tabletd}>
                                {admin.role == 'GOD' && (
<>
                                        <FaEdit onClick={() => { edit(promo, false, true, false) }} className={style.icon} />

                                    </>)}
                                    {promo.sale}%</td>
                                <td className={style.tabletd}>
                                    {promo.status == 'on' && (
                                        <Toggle
                                            label={promo.status}
                                            toggled={true}
                                            onClick1={() => submitStatus(updatestatus, { status: 'off', id: promo.id })} />
                                    )}
                                    {promo.status == 'off' && (
                                        <Toggle
                                            label={promo.status}
                                            toggled={false}
                                            onClick1={() => submitStatus(updatestatus, { status: 'on', id: promo.id })} />
                                    )}




                                </td>
                                <td className={style.tabletd}> {promo.updatedAt}</td>
                                {admin.role == 'GOD' && (
<>
                                    <td className={style.tabletd}>
                                        <AiFillDelete onClick={() => { submit(deleteProd, promo.id) }} className={style.icon} />
                                    </td>
                                </>)}

                            </tr>

                        </>
                    )}
                </tbody>
            </table>
            {modalPromo && (
                <Modalpromo
                    thisChange={thisChange}
                    setThisChange={setThisChange}
                // prod = {prod}
                />

            )}
        </div>
    </>
    )
}
export default PromoCodesComponent