
import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Modal.module.css'
// import Title from "../../Title";
import Button from '../../../../Button/Button'
import $api from "../../../../../http/index.ts";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
import axios from "axios";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { FileUploader } from "react-drag-drop-files";
const ModalEditProduct = ({ id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { servOrders, sclad, setServOrders, admin, setModalInfo, infomodal, modalEditProduct, setModalEditProduct } = useContext(QtdContext);
  const [newValue, setNeValue] = useState('')
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container124" && setModalEditProduct(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  // const { modal, setModal } = useState(false);
  const [loading, setLoading] = useState(false)
  const [imageFile, setImageFile] = useState(null);
  // useEffect(() => {
  //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  // }, [])
  console.log(id)
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      alert('HEIC/HEIF images are not supported. Please convert your image to JPEG or PNG.');
    }
    setFile(file);
    setFileURL(URL.createObjectURL(file))
    // console.log(file)
  };
  const product = sclad.find(pr => pr.id == id)
  // setFileURL(product.photo[0])
    const [fileURL, setFileURL] = useState(product.photo[0] );

  const [newProd, setNewProd] = useState({ ...product, photo: product.photo[0] })
  const [err, setErr] = useState({})
  const create = async (e) => {
    try {
      e.preventDefault();

      let t = {
        name: false,
        kategory: false,
        podkategory: false,
        scladqty: false,
        forwho: false,
        price: false,
        shcode: false,
        about: false,
        photo: false,
        recomendation: false,
        mnt: false,
        brand: false
      }

      if (!newProd.name) {
        t.name = 'Наименование обязательно к заполнению!'
      }
      if (!newProd.kategory) {
        t.kategory = 'Категория обязательна к выбору!'
      }
      if (newProd.kategory == 'Каталог товаров') {
        if (!newProd.podkategory) {
          t.podkategory = 'Подкатегория обязательна к выбору!'
        }

      }

      if (!newProd.brand) {
        t.brand = 'brand обязательн к заполнению!'
      }
      if (!newProd.mnt) {
        t.mnt = 'Mnt обязательн к заполнению!'
      }
      if (!newProd.price) {
        t.price = 'Розничная цена обязательна к заполнению!'
      }
      if (!newProd.forwho) {
        t.forwho = 'Описание обязательно к заполнению!'
      }
      if (!newProd.shcode) {
        t.shcode = 'ШК обязательно к заполнению!'
      }
      // if (!file) {
      //     t.photo = 'photo обязательно к заполнению!'
      // }
      if (!newProd.recomendation) {
        t.recomendation = 'recomendation обязательно к заполнению!'
      }
      console.log(t)
      setErr(t)
      if (
        newProd.name &&
        newProd.kategory &&
        newProd.mnt &&
        newProd.price &&
        newProd.brand &&
        newProd.forwho &&
        newProd.shcode &&
        // file &&
        // newProd.recomendation &&
        !t.mnt &&
        !t.brand &&
        !t.name &&
        !t.kategory &&
        // !t.podkategory &&
        !t.price &&
        !t.about &&
        !t.shcode &&
        !t.photo &&
        !t.forwho
        // !t.recomendation
      ) {
        if (!file) {
          alert('Пожалуйста, выберите файл для загрузки.');
          return;
        }
        const formData = new FormData();
        // const [file, setFile] = useState(null);
        formData.append('image', file);
        formData.append('name', newProd.name); // дополнительное поле
        formData.append('podkategory', newProd.podkategory);
        formData.append('price', newProd.price); // дополнительное поле
        formData.append('about', newProd.about);
        formData.append('shcode', newProd.shcode); // дополнительное поле
        // formData.append('photo', newProd.photo);
        // formData.append('name', newProd.name); // дополнительное поле
        formData.append('recomendation', newProd.recomendation);
        formData.append('brand', newProd.brand);
        formData.append('mnt', newProd.mnt);
        setLoading(true)



        console.log('formData', formData, file, fileURL)
        // const response = await fetch(`${BACKURL}/uploadpick`, {
        //     method: 'POST',
        //     body: formData,
        // });
        // const response = await $api.post(`${BACKURL}/uploadpick`, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // });

        // setLoading(false  )
        // if (response) {
        //     // const { url } = response.data;
        //     console.log(response.data.url)
        //     // setImageUrl(url);
        // } else {
        //     console.error('Failed to upload image');
        // }



        setLoading(false)

        alert('Готово!')
      }

      // console.log(newProd)
    } catch (e) {
      setLoading(false)
      alert('Ошибка!')
      // console.log(e)
    }

  }

  return (
    <>
      {modalEditProduct && (
        <div onClick={closeModal} id="container124" className={style.modal_container}>

          <div className={style.modal}>


            {/* <img id={id} className={style.img} src={tt} /> */}
            <div className={style.imgcont} style={{ height: '250px' }}>
              <LazyLoadImage
                // id={id}
                className={style.img}
                src={fileURL}
                alt="Example Image"
                height="100%"
                effect="blur"

              />
            </div>
            <FileUploader handleChange={handleChange} name="file"
            //  types={fileTypes}
            />
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.name ? 'red' : 'black' }} for="name">Введите Наименование:</label>

                <input value={newProd.name} type="text" id="name" name="name" onChange={(event) => {
                  setNewProd({ ...newProd, name: event.target.value });
                }} />
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.kategory ? 'red' : 'black' }} for="pet-select">Выберите Категорию:</label>

                <select value={newProd.kategory} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, kategory: event.target.value });
                }}>
                  <option value="">--Выберите Категорию--</option>
                  <option value="фарш">фарш</option>
                  <option value="мясные консервы для кошек">мясные консервы для кошек</option>
                  <option value="мясные консервы для собак">мясные консервы для собак</option>
                  {/* <option value="сушенная продукция для собак">сушенная продукция для собак</option>
                            <option value="сушенная продукция для кошек">сушенная продукция для кошек</option> */}
                  <option value="отдельная мороженная продукция">отдельная мороженная продукция</option>
                  <option value="сухой корм">сухой корм</option>
                  <option value="рацион">рацион</option>
                  <option value="Каталог товаров">Каталог товаров</option>
                </select>

                {/* {err.kategory && (
                            <p style={{ color: "red" }}>{err.kategory}</p>
                        )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.forwho ? 'red' : 'black' }} for="pet-select">Для кого:</label>

                <select value={newProd.forwho} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, forwho: event.target.value });
                }}>
                  <option value="">--Выберите для кого продукция--</option>

                  <option value="Для кошек">Для кошек</option>
                  <option value="Для собак">Для собак</option>
                  <option value="Для кошек и собак">Для кошек и собак</option>
                </select>

                {/* {err.forwho && (
                            <p style={{ color: "red" }}>{err.forwho}</p>
                        )} */}
              </div>
              {newProd.kategory == 'Каталог товаров' && (
                <>

                  <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                    <label style={{ color: err.podkategory ? 'red' : 'black' }} for="pet-select">Выберите Подкатегорию:</label>

                    <select value={newProd.podkategory} name="pets" id="pet-select" onChange={(event) => {
                      setNewProd({ ...newProd, podkategory: event.target.value });
                    }}>
                      <option value="">--Выберите Подкатегорию--</option>
                      {newProd.forwho == 'Для кошек' && (
                        <option value="Наполнитель">Наполнитель</option>

                      )}
                      <option value="ВетАптека">ВетАптека</option>
                      <option value="Игрушки">Игрушки</option>
                      <option value="сухой корм">сухой корм</option>
                      <option value="Шампунь">Шампунь</option>
                    </select>

                    {/* {err.podkategory && (
                                    <p style={{ color: "red" }}>{err.podkategory}</p>
                                )} */}
                  </div>
                </>
              )}

              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.mnt ? 'red' : 'black' }} for="pet-select">Для кого:</label>

                <select value={newProd.mnt} name="pets" id="pet-select" onChange={(event) => {
                  setNewProd({ ...newProd, mnt: event.target.value });
                }}>
                  <option value="">--Выберите ед.--</option>

                  <option value="шт">шт</option>
                  <option value="кг">кг</option>
                  {/* <option value="л">л</option> */}
                </select>

                {/* {err.forwho && (
                            <p style={{ color: "red" }}>{err.forwho}</p>
                        )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.brand ? 'red' : 'black' }} for="brand">Введите бренд:</label>
                <input value={newProd.brand} type="text" id="brand" name="brand" onChange={(event) => {
                  setNewProd({ ...newProd, brand: event.target.value });
                }} />
                {/* {err.price && (
                            <p style={{ color: "red" }}>{err.price}</p>
                        )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.price ? 'red' : 'black' }} for="price">Введите розничную цену:</label>
                <input value={newProd.price} type="text" id="price" name="price" onChange={(event) => {
                  setNewProd({ ...newProd, price: event.target.value });
                }} />
                {/* {err.price && (
                            <p style={{ color: "red" }}>{err.price}</p>
                        )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.recomendation ? 'red' : 'black' }} for="text">Введите рекомендации÷ товара:</label>
                <textarea value={newProd.recomendation} type="text" style={{ height: '200px' }} id="recomendation" name="text" onChange={(event) => {
                  setNewProd({ ...newProd, recomendation: event.target.value });
                }} />
                {/* {err.recomendation && (
                            <p style={{ color: "red" }}>{err.recomendation}</p>
                        )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.about ? 'red' : 'black' }} for="text">Введите Описание для нового товара:</label>
                <textarea value={newProd.about} type="text" style={{ height: '200px' }} id="about" name="text" onChange={(event) => {
                  setNewProd({ ...newProd, about: event.target.value });
                }} />
                {/* {err.about && (
                            <p style={{ color: "red" }}>{err.about}</p>
                        )} */}
              </div>
              <div className={style.inputdiv} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <label style={{ color: err.shcode ? 'red' : 'black' }} for="shcode">Введите ШК:</label>
                <input value={newProd.shcode} type="text" id="shcode" name="shcode" onChange={(event) => {
                  setNewProd({ ...newProd, shcode: event.target.value });
                }} />
                {/* {err.shcode && (
                            <p style={{ color: "red" }}>{err.shcode}</p>
                        )} */}
              </div>
            </div>
            {/* <Button text='Создать!' onClick={create} /> */}




            {!loading && (
              <Button
                text="Изменить кол-во"

                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={create}
              />
            )}
            {loading && (
              <Button
                text="Изменяем..."

                background="var(--primary-color)"
                color="white"
                width="100%"
                disabled={true} />
            )}

          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default ModalEditProduct;
