import React, { useContext, useEffect, useState } from "react";
import style from "./ModalInfo.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { FcCancel, FcServices } from "react-icons/fc";
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../NewOrders/react-confirm-alert.css'; // Import css
import ModalEditProduct from "./ModalEditProduct";
import ModalAddProduct from "./ModalAddProduct";
import { BACKURL } from '../../../../../conf.js'
const ModalInfoScladHistory = ({ list }) => {

    // const navigate = useNavigate();
    // const produtosCart = JSON.parse(localStorage.getItem("pet"));
    // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

    const {scladHistory, modalInfoScladHistory, setModalInfoScladHistory} = useContext(QtdContext);
    const closeModal = ({ target }) => {
        // console.log(modalInfo)
        target.id === "container1323" && setModalInfoScladHistory(false);
        // target.innerText === "ОТМЕНИТЬ" && setModal(false);
    };
    // console.log(infomodal)
//    useEffect(()=>{
    const Arr = list.map(pr=>JSON.parse(pr))
//    },[])




    return (
        <>
            {modalInfoScladHistory && (
                <div onClick={closeModal} id="container1323" className={style.modal_container}>

                    <div className={style.modal}>
                       
                      
                           
                            <div style={{ overflowX: 'auto',width:'100%' }}>
                                <table className={style.table}>
                                    <thead className={style.tableth}>
                                        <tr>
                                            <td className={style.tabletd}>№</td>
                                            <td className={style.tabletd}>ШК</td>
                                            <td className={style.tabletd}>Наименование</td>
                                            <td className={style.tabletd}>Категория</td>
                                            <td className={style.tabletd}>Кол-во</td>
                                            {/* <td className={style.tabletd}>Склад</td> */}
                                            <td className={style.tabletd}>Ед</td>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {Arr.map((prod, index) => {
                                            return (<tr>
                                                <td className={style.tabletd}>{index + 1}</td>
                                                <td className={style.tabletd}>{prod.shcode}</td>
                                                <td className={style.tabletd}>{prod.name}</td>
                                                <td className={style.tabletd}>{prod.kategory}</td>
                                                <td className={style.tabletd}>{prod.qty}</td>
                                                {/* <td className={style.tabletd}>{prod.newqty}</td> */}
                                                <td className={style.tabletd}>{prod.mnt}</td>
                                            </tr>)
                                        }

                                        )}
                                    </tbody>
                                </table>
                            </div>
                          
                     
                    </div>

                </div>

                // </div>
            )}

        </>
    );
};

export default ModalInfoScladHistory;
