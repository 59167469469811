import React, { useContext, useEffect, useState } from "react";
import Button from '../../Button/Button'
import styleС from '../Contato/Contato.module.css'
import Title from "../../Title";
import { QtdContext } from "../../../context/qtdContext";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
const Success = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    // const { newOrderID, setNewOrderID } = useContext(QtdContext);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
setTimeout(()=>{navigate('/')},10000)
        // if (Object.keys(newOrderID).length == 0) {
          // console.log('eerf')
    
          // return (<>
          //   <Title text="Сначала добавьте товар в корзину!" />
          // </>)
          // navigate('/');
          return
        }, [])
    // const { newOrder } = useContext(QtdContext);

    // const { newOrder, setNewOrder, produtosSelecionados } = useContext(QtdContext);
    // console.log(produtosSelecionados)
let text = `Спасибо что выбрали нас! Номер вашего заказа${id}! В скором времени с васм свяжется менеджер для подтверждения заказа!`
    return (<>
        <Title text={text} />
       
      
    </>)
}
export default Success