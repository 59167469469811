import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../../../Modal/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../../../conf.js'
import ModalInfoSpec from '../../../Modal/ModalInfoSpec';
const SpecList = () => {
    const { sclad, admin, spec, setSclad, modalInfoSpec, setModalInfoSpec } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    // const cuns = sclad.filter((post) => post.kategory.includes('Производство - Мясо'))

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    const [thisChange, setThisChange] = useState({})

    const opan = async (id, reciept) => {
                console.log(reciept)

        setThisChange({
            id: id,
            reciept: reciept,

        })
        setModalInfoSpec(true)

    }
    // const deleteProd = async (id) => {
    //     const DeletedId = await $api.get(`${BACKURL}/api/utbteam/deleteproduct/${id}`)
    //     const newprod = sclad.filter((post) => post.id != id)
    //     setSclad(newprod)
    //     alert('Товар удален!')
    // }
    // const submit = async (func, data) => {
    //     confirmAlert({
    //         title: 'Подтвердите, чтобы продолжить!',
    //         message: 'Вы уверены, в том, что хотите удалить товар?',
    //         buttons: [
    //             {
    //                 label: 'Да!',
    //                 onClick: async () => await func(data)
    //             },
    //             {
    //                 label: 'Нет!',
    //                 //   onClick: () => alert('Click No')
    //             }
    //         ],
    //         closeOnEscape: true,
    //         closeOnClickOutside: true,
    //     });
    // };

    return (<>
        <div style={{ overflowX: 'auto' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>Наименоване</td>
                        <td className={style.tabletd}>Планируемое кол-во</td>
                        <td className={style.tabletd}>Ед</td>
                        <td className={style.tabletd}>Cпецификация</td>


                        {/* <td className={style.tabletd}>Остаток</td>
                        <td className={style.tabletd}>Ед</td> */}

                    </tr>
                </thead>
                <tbody>
                    {spec.map(
                        (prod, index) => <>
                            <tr>


                                <td className={style.tabletd}>
                                    {/* {admin.user == 'ieresko' && (<>
                                        <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, true, false, false) }} className={style.icon} />
                                    </>)} */}
                                    {prod.name} </td>
                                <td className={style.tabletd}>
                                    {/* {admin.user == 'ieresko' && (<>
                                        <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, true, false, false) }} className={style.icon} />
                                    </>)} */}
                                    {prod.planedqty} </td>
                                <td className={style.tabletd}>
                                    {/* {admin.user == 'ieresko' && (<>
                                        <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, true, false, false) }} className={style.icon} />
                                    </>)} */}
                                    {prod.newplanedqtymnt} </td>
                                <td className={style.tabletd}>
                                    {/* {admin.user == 'ieresko' && (<> */}
                                    {/* <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} /> */}
                                    <FcInfo onClick={()=>opan(prod.id,prod.usedResources)} className={style.icon} />
                                    {/* </>)} */}
                                </td>


                            </tr>

                        </>
                    )}
                </tbody>
            </table>
            {modalInfoSpec && (
                <ModalInfoSpec
                    id={thisChange.id}
                    reciept={thisChange.reciept}

                    setThisChange={setThisChange}
                // prod = {prod}
                />

            )}
        </div>
    </>
    )
}
export default SpecList