import React from 'react'
import Button from '../../Button/Button'
import style from './Admin.module.css'
import Title from "../../Title";
import axios from 'axios';
import { QtdContext } from "../../../context/qtdContext";
import { useEffect, useState, useContext } from "react";
import $api from "../../../http/index.ts";
import Dashboard from '../DashBoard/Dashboard';
import { BACKURL } from '../../../conf.js'
const OptUser = () => {
    const { optUsers, setOptUsers, setAdmin, opt, setOpt } = useContext(QtdContext);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, [])

    const [logdata, setLogData] = useState({
        login: '',
        pass: ''
    })
    const LogIn = async () => {
        const user = await $api.post(`${BACKURL}/api/optuser/login`, logdata)
        console.log(user)    
        // setAdmin(JSON.parse(user.data.user))
        localStorage.setItem('token', user.data.accessToken);
        setOpt({ auth: true, user: user.data.user })
        // setAdmin(user.data.user);


    }
    const LogOut = async () => {
        await $api.post(`${BACKURL}/api/optuser/logout`)
        setOpt({ auth: false, user: {} })
        return
    }
    return (
        <>
            {!opt.auth && (<>


                <Title text="Вход" />
                <div className={style.container}>
                    <p>Уважаемые клиенты, данная страница предназначена для оптовых покупателей и наших партнеров! Чтобы получить доступ к данному разделу, запросите регистрационные данные у своего менеджера!</p>

                    <div className={style.search}>

                        <form className={style.form}>
                            <label for="n"><h4>Введите логин!</h4></label>
                            <input type="text" id="n" name="n" onChange={(event) => {
                                setLogData({ ...logdata, login: event.target.value });
                            }} />
                            <label for="tel"><h4>Введите пароль!</h4></label>
                            <input type="password" id="tel" name="tel" onChange={(event) => {
                                setLogData({ ...logdata, pass: event.target.value });
                            }} />

                        </form>
                        <Button text='Войти' onClick={LogIn} />

                    </div>


                </div>
            </>
            )}
            {opt.auth && (<>
                <Title text="Вам доступны оптовые цены на сайте!" />
                <div className={style.container}>

                    <Button text='Выйти' onClick={LogOut} />
                </div>
            </>
            )}
        </>
    )
}

export default OptUser