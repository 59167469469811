import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';

const ModalEdit = ({ thisChange, setThisChange, prod }) => {

  const navigate = useNavigate();
  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { sclad, serverProd, setSclad, setServerProd, modalEdit, setModalEdit, admin } = useContext(QtdContext);
  // console.log(thisChange)
  const [buttonsCheck, setbuttonsCheck] = useState(false);
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12" && setModalEdit(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };


  const editPrice = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updateprice/${thisChange.prod.id}`,
        { pr: thisChange.priceValue, admin: admin.user }
      )
      const newPosts = serverProd.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, price: newProd.data.price }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)

      alert('Цена успешно обновлена в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }

  }

  const editOptPrice = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updateoptprice/${thisChange.prod.id}`,
        { pr: thisChange.optValue, admin: admin.user }
      )
      const newPosts = serverProd.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, optprice: newProd.data.optprice }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)

      alert('Цена успешно обновлена в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }

  }

  const editQty = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updateqty/${thisChange.prod.id}`,
        { qt: thisChange.qtyValue, admin: admin.user }
      )
      const newPosts = sclad.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, scladqty: newProd.data.scladqty }
          : post
      ));

      setSclad(newPosts)
      setbuttonsCheck(false)
      alert('Кол-во успешно обновлено в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }


  }
  const editName = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updatename/${thisChange.prod.id}`,
        { name: thisChange.nameValue, admin: admin.user }
      )
      const newPosts = serverProd.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, name: newProd.data.name }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)
      alert('Кол-во успешно обновлено в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }


  }
  const editMnt = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updatemnt/${thisChange.prod.id}`,
        { mnt: thisChange.mntValue, admin: admin.user }
      )
      const newPosts = sclad.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, mnt: newProd.data.mnt }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)
      alert('Кол-во успешно обновлено в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }


  }
  const editSHcode = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updateshcode/${thisChange.prod.id}`,
        { shcode: thisChange.shcodeValue, admin: admin.user }
      )
      const newPosts = serverProd.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, shcode: newProd.data.shcode }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)
      alert('Кол-во успешно обновлено в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }


  }
  const editAbout = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updateabout/${thisChange.prod.id}`,
        { about: thisChange.aboutValue, admin: admin.user }
      )
      const newPosts = serverProd.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, text: newProd.data.about }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)
      alert('Кол-во успешно обновлено в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }


  }
  const editRec = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updaterecomendation/${thisChange.prod.id}`,
        { recomendation: thisChange.recomendationValue, admin: admin.user }
      )
      const newPosts = serverProd.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, recomendation: newProd.data.recomendation }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)
      alert('Кол-во успешно обновлено в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }


  }

  const editVipPrice = async () => {
    try {
      setbuttonsCheck(true)

      const newProd = await $api.post(`${BACKURL}/api/utbteam/updatevipprice/${thisChange.prod.id}`,
        { qt: thisChange.vippriceValue, admin: admin.user, optUser: thisChange.vippriceOptUser }
      )
      const newPosts = sclad.map((post) => (
        post.id == thisChange.prod.id
          ? { ...post, vipprice: newProd.data.vipprice.map(pr => JSON.parse(pr)) }
          : post
      ));

      setSclad(newPosts)
      setServerProd(newPosts)
      setbuttonsCheck(false)
      alert('Кол-во успешно обновлено в системе!')
      setModalEdit(false)
    } catch (e) {
      setbuttonsCheck(false)
      console.log(e)
    }


  }

  return (
    <>
      {modalEdit && (
        <div onClick={closeModal} id="container12" className={style.modal_container}>
          {/* <div className={style.container_modal}> */}

          {/* TEST */}
          {thisChange.name && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.name}`}></Title>
              </div>
              <form className={style.form}>
                <label for="кол-во">Введите новое имя:</label>
                <input type="text" id="кол-во" name="кол-во" onChange={(event) => {
                  setThisChange({ ...thisChange, nameValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить имя"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editName}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
          {thisChange.vipprice && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.vippriceOptUser} - ${thisChange.prod.name}`}></Title>
              </div>
              <form className={style.form}>
                <label for="кол-во1">Введите новую цену:</label>
                <input type="text" id="кол-во1" name="кол-во1" onChange={(event) => {
                  setThisChange({ ...thisChange, vippriceValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить ед"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editVipPrice}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
          {thisChange.mnt && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.mnt}`}></Title>
              </div>
              <form className={style.form}>
                <label for="кол-во1">Введите новое измерение:</label>
                <input type="text" id="кол-во1" name="кол-во1" onChange={(event) => {
                  setThisChange({ ...thisChange, mntValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить ед"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editMnt}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}

          {thisChange.shcode && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.shcode}`}></Title>
              </div>
              <form className={style.form}>
                <label for="кол-во">Введите новый штрихкод:</label>
                <input type="text" id="кол-во" name="кол-во" onChange={(event) => {
                  setThisChange({ ...thisChange, shcodeValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить штрихкод"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editSHcode}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
          {thisChange.qty && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.name}`}></Title>
              </div>
              <form className={style.form}>
                <label for="кол-во">Введите новое кол-во:</label>
                <input type="number" id="кол-во" name="кол-во" onChange={(event) => {
                  setThisChange({ ...thisChange, qtyValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить кол-во"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editQty}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
          {thisChange.price && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.name}`}></Title>
              </div>
              <form className={style.form}>
                <label for="РЦ">Введите новую розничную цену:</label>
                <input type="number" id="РЦ" name="РЦ" onChange={(event) => {
                  setThisChange({ ...thisChange, priceValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить цену"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editPrice}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
          {thisChange.optprice && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.name}`}></Title>
              </div>
              <form className={style.form}>
                <label for="РЦ">Введите новую оптовую цену:</label>
                <input type="number" id="РЦ" name="РЦ" onChange={(event) => {
                  setThisChange({ ...thisChange, optValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить цену"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editOptPrice}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
          {thisChange.about && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.name}`}></Title>
              </div>
              <form className={style.form}>
                <label for="aboutValue">Введите новое описание:</label>
                <textarea type="text" id="aboutValue" name="aboutValue" onChange={(event) => {
                  setThisChange({ ...thisChange, aboutValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить описание"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editAbout}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
          {thisChange.recomendation && (
            <div className={style.modal}>
              <div>
                <Title text={`${thisChange.prod.name}`}></Title>
              </div>
              <form className={style.form}>
                <label for="aboutValue">Введите новые рекомендации:</label>
                <textarea type="text" id="aboutValue" name="aboutValue" onChange={(event) => {
                  setThisChange({ ...thisChange, recomendationValue: event.target.value });
                }} />
                {/* {error.name && (
                  <p style={{ color: "red" }}>{error.name}</p>
                )} */}
              </form>
              {!buttonsCheck && (
                <Button
                  text="Изменить рекомендации"

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  onClick={editRec}
                />
              )}
              {buttonsCheck && (
                <Button
                  text="Изменяем..."

                  background="var(--primary-color)"
                  color="white"
                  width="100%"
                  disabled={true}
                />
              )}

            </div>
          )}
           {buttonsCheck&&(
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
          {/* <Button
              text="Оформить заказ"

              background="var(--primary-color)"
              color="white"
              width="100%"
              onClick={() => StarMakeOrder()}
            /> */}
        </div>

        // </div>
      )}

    </>
  );
};

export default ModalEdit;