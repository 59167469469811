import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewOrders.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalInfo from '../../../Modal/ModalInfo';
import Button from '../../../../../../Button/Button';
import { FcInfo, FcCancel } from "react-icons/fc";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { BACKURL } from '../../../../../../../conf.js'
const FactoryCircle = ({ status }) => {
    const { factory, setfactory,admin, setInfoModal, modalInfo, setModalInfo, servOrders, setServOrders } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    // const servOrders = JSON.parse(localStorage.getItem("servOrders"));

    //factory state
    const drydey = factory.filter((prod) => prod.status == status)
    // drydey.reverse()
    let NextStatus = ''
    let NextButton = ''
    switch (true) {
      
        case (status == 'Запланировано!'):
            NextStatus = 'Производится!'
            NextButton = 'Начать!'

            break
        case (status == 'Производится!'):
            NextStatus = 'Произведено!'
            NextButton = 'Произведено!'

            break
     
    }
    // const Nesx = async (id, newStatus) => {
    //     // console.log(id, newStatus)

    //     const updated = await $api.post(`${BACKURL}/api/utbteam/updateorderstatus/${id}`, { newStatus: newStatus, admin: admin.user })
    //     // console.log(updated)
    //     const newPosts = servOrders.map((post) => (
    //         post.id == updated.data.id
    //             ? { ...post, status: updated.data.status }
    //             : post
    //     ));

    //     // setSclad(newPosts)
    //     setServOrders(newPosts)
    //     // console.log(newProd.data)
    //     //   localStorage.setItem("servOrders", JSON.stringify(servOrders));
    //     // console.log(newPosts)
    //     alert('Заказ перемещен!')
    // }
    // // console.log('servOrders', drydey)

    // const ModalInfoB = async (what, type, data, prod, bonus, promo,orderlength) => {
    //     setInfoModal({ type: type, what: what, data: data, order_id: prod, bonus:bonus, promo:promo,orderlength:orderlength })
    //     setModalInfo(true)

    // }
    // const deleteOrder = async (id) => {
    //     try {
    //         // console.log('deleteOrder', id)
    //         const deleteOrderId = await $api.post(`${BACKURL}/api/utbteam/deleteorder/${id}`, { admin: admin.user })
    //         const newPosts = servOrders.filter((post) => (
    //             post.id != deleteOrderId.data
    //         ));

    //         // setSclad(newPosts)
    //         setServOrders(newPosts)
    //         // console.log(newPosts)
    //         alert('Заказ удален!')

    //     } catch (e) {
    //         // console.log(e)
    //     }
    // }
    // const submitDeket = async (func, data) => {
    //     confirmAlert({
    //         title: 'Подтвердите, чтобы продолжить!',
    //         message: 'Вы уверены в том, что хотите удалить заказ?',
    //         buttons: [
    //             {
    //                 label: 'Да!',
    //                 onClick: async () => await func(data)
    //             },
    //             {
    //                 label: 'Нет!',
    //                 //   onClick: () => alert('Click No')
    //             }
    //         ],
    //         closeOnEscape: true,
    //         closeOnClickOutside: true,
    //     });
    // };
    // const submit = async (func, data) => {
    //     confirmAlert({
    //         title: 'Подтвердите, чтобы продолжить!',
    //         message: 'Вы уверены, в том, что хотите обновить статус ордера?',
    //         buttons: [
    //             {
    //                 label: 'Да!',
    //                 onClick: async () => await func(data.id, data.NextStatus)
    //             },
    //             {
    //                 label: 'Нет!',
    //                 //   onClick: () => alert('Click No')
    //             }
    //         ],
    //         closeOnEscape: true,
    //         closeOnClickOutside: true,
    //     });
    // };
    return (<>
   
        <div style={{ overflowX: 'auto' }}>
                 <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>Имя</td>
                        <td className={style.tabletd}>Расчетное количество</td>
                        {/* //в выполнено */}
                        <td className={style.tabletd}>Фактическое количество</td>
                        <td className={style.tabletd}>Планируемая дата начала</td>
                        <td className={style.tabletd}>Дата начала</td>
                        <td className={style.tabletd}>Дата конца</td>
                        <td className={style.tabletd}>Требуемые ресурсы</td>
                        <td className={style.tabletd}>Используемые ресурсы</td>
                        <td className={style.tabletd}>Ед</td>
                        <td className={style.tabletd}>Действия</td>
                        

                    
                    </tr>
                </thead>
                <tbody>
                    {drydey.map(
                        (prod, index) => <>
                            <tr>
                                <td className={style.tabletd}>{prod.id}</td>
                                <td className={style.tabletd}>{prod.name}</td>
                                <td className={style.tabletd}>{prod.plandeQty}</td>
                                <td className={style.tabletd}></td>
                                <td className={style.tabletd}>{prod.planedData}</td>
                                <td className={style.tabletd}></td>
                                <td className={style.tabletd}></td>
                                <td className={style.tabletd}></td>
                                <td className={style.tabletd}></td>

                                <td className={style.tabletd}>{prod.createdAt}</td>

                                {(status != 'Получен!' ) && (

                                    <td className={style.tabletd}>
                                        {/* <Button text={NextButton} onClick={() => submit(Nesx, { id: prod.id, NextStatus })} /> */}
                                        </td>
                                )}

                            </tr>

                        </>
                    )}
                </tbody>
            </table>
        </div>
        {/* {modalInfo && (
            <ModalInfo
                status={status}
            /> */}

        {/* )} */}
    </>
    )
}
export default FactoryCircle