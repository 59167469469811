import React, { useContext, useEffect, useState } from "react";
import Button from '../../Button/Button'
import style from './Contato.module.css'
import styleС from '../Contato/Contato.module.css'
import Title from "../../Title";
import { QtdContext } from "../../../context/qtdContext";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import RadioButton from './Radio/RadioButton'
import { BACKURL } from '../../../conf.js'
const OptOrder = () => {
  const navigate = useNavigate();

  const [error, setError] = useState({
    name: false,
    phone: false,
    email: false
  });
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let telformat = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/

  const { opt, newOrderID, setNewOrderID, produtosSelecionados, setProdutosSelecionados } = useContext(QtdContext);
  // console.log(produtosSelecionados)

  const [newOrder, setNewOrder] = useState({ DiliveryType: 'Доставка', DiliveryCity: '', DiliveryAdress: '', DiliveryHouse: '', DiliveryFlat: '' });

  const [value, setValue] = useState('Доставка');

  function chengeValue(target) {
    setValue(target);
    // console.log(value)
  }



  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (produtosSelecionados.length == 0) {
      // console.log('eerf')

      // return (<>
      //   <Title text="Сначала добавьте товар в корзину!" />
      // </>)
      navigate('/');
      return
    }
  }, [])

  let summ = 0
  produtosSelecionados.map(({ optprice, vipprice, quantidade }) => {
    console.log(vipprice)
    const vp = vipprice.find(vp => vp.optuserid == opt.user.id)
    if (vp.vipprice > 0) {
      summ = summ + Number(quantidade) * Number(vp.vipprice)
    } else {
      summ = summ + Number(quantidade) * Number(optprice)
    }

  })

  //  produtosSelecionados.map(({ optprice, vipprice, quantidade }, index) => (

  // summ = summ + Number(quantidade) * Number(optprice)))


  const SendOrder = async (e) => {
    try {
      // console.log(newOrder)
      e.preventDefault();


      let t = {

        adress: false,

      }

      // if (!newOrder.DiliveryType) {
      //   t.DiliveryType = 'Необходимо выбрать способ доставки!'
      //   // setError({ ...error, name: 'Имя обязателено для заполнения!' });
      // }

      // if (newOrder.DiliveryType != 'Самовывоз') {
      //   if (!newOrder.adress) {
      //     t.adress = 'Необходимо выбрать способ доставки!'
      //     // setError({ ...error, name: 'Имя обязателено для заполнения!' });
      //   }

      // }


      setError(t)

      if (
        newOrder.DiliveryType) {
        // e.preventDefault();
        // const ord = {
        //   adress: newOrder.adress,
        //   DiliveryType: newOrder.DiliveryType,
        //   name: opt.user.org,
        //   email: opt.user.email,
        //   phone: opt.user.phone
        // }
        // setNewOrder(ord);

        const sendedOrder = await axios.post(`${BACKURL}/api/newoptorderreact`, { opt: opt.user.id, user: newOrder, order: produtosSelecionados })
        console.log('new order', sendedOrder.data)
        setProdutosSelecionados([])
        localStorage.setItem("produtosSelecionados", JSON.stringify([]));
        setNewOrderID(sendedOrder.data)


        setTimeout(() => {
          setNewOrderID('')
          navigate('/cuns')
          setNewOrderID('')
        }, 18000)
        // navigate('/success')
      }

      // console.log('Error')

      // console.log(newOrder, produtosSelecionados, error)

    } catch (e) {
      // console.log(e)
    }

  }








  return (<>
    {!newOrderID && (
      <>
        <Title text="Оформление оптового заказа!" />
        <div className={style.border}>

          <h3 style={{ textAlign: 'center' }}>Ваш заказ:</h3>
          <p className={style.p}>


          </p>


          {/* <br></br> */}
          <div className={style.container_table}>

            <table className={style.table}>
              {/* <table> */}
              <thead className={style.tableth}>
                <tr>
                  <td className={style.tabletd}>№</td>
                  <td className={style.tabletd}>Наименование</td>
                  <td className={style.tabletd}>Кол-во</td>
                  <td className={style.tabletd}>Цена</td>
                  <td className={style.tabletd}>Сумма</td>
                </tr>
              </thead>
              <tbody>
                {produtosSelecionados.map(({ name, vipprice, quantidade, optprice }, index) => (
                  <tr>
                    <td className={style.tabletd}>{index + 1}</td>
                    <td className={style.tabletd}>{name}</td>
                    <td className={style.tabletd}>{quantidade}</td>
                    {vipprice.find(vp => vp.optuserid == opt.user.id).vipprice > 0 && (
                      <td className={style.tabletd}>{vipprice.find(vp => vp.optuserid == opt.user.id).vipprice} RUB</td>

                    )}
                    {vipprice.find(vp => vp.optuserid == opt.user.id).vipprice == 0 && (
                      <td className={style.tabletd}>{optprice} RUB</td>

                    )}
                    {vipprice.find(vp => vp.optuserid == opt.user.id).vipprice > 0 && (
                      <td className={style.tabletd}>{Number(quantidade) * Number(vipprice.find(vp => vp.optuserid == opt.user.id).vipprice)} RUB</td>

                    )}
                    {vipprice.find(vp => vp.optuserid == opt.user.id).vipprice == 0 && (
                      <td className={style.tabletd}>{Number(quantidade) * Number(optprice)} RUB</td>

                    )}
                    {/* <td className={style.tabletd}>{Number(quantidade) * Number(optprice)} RUB</td> */}
                  </tr>
                ))}



                <tr>
                  <td>Итого</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{summ} RUB</td>

                </tr>




              </tbody>
            </table>
          </div>
          {/* <br></br> */}
          <p className={style.p} style={{ textAlign: 'center', marginTop: '10px' }}>Заполните данные для оформления заказа! После создания заказа с вами свяжется менеджер!</p>

          <div className={styleС.container_form}>
            <form className={styleС.form}>

              <div className={style.radiocont}>
                <div className={style.radiocont2}>
                  <label for="Доставка">Доставка:</label>

                  <input className={style.radioinput} type="radio" id="Доставка" name="radio" value="Доставка"
                    checked={value == 'Доставка' ? true : false}
                    onChange={(event) => {
                      setValue('Доставка')
                      setNewOrder({ ...newOrder, DiliveryType: event.target.value });
                    }} />
                  <br></br>
                </div>
                <div className={style.radiocont2}>
                  <label for="Самовывоз">Самовывоз:</label>

                  <input className={style.radioinput} type="radio" id="Самовывоз" name="radio" value="Самовывоз"
                    checked={value == 'Самовывоз' ? true : false}
                    onChange={(event) => {
                      setValue('Самовывоз')
                      setNewOrder({ ...newOrder, DiliveryType: event.target.value });
                    }} />
                </div>
              </div>
              {value == "Самовывоз" && (
                <p>Самовывоз доступен с нашего производства по адресу 5-ая Причальная 1!</p>
              )}
              {value == "Доставка" && (<>

                {/* <form className={style.adress}>

                  {/* <div className={style.adress1}> */}
                  {/* <AddressSuggestions token="53c39fd794ed643eb8cc7503cac74e006249e689" value={value} onChange={setValue} />; */}
                  <div className={style.adressInput}>
                    <label for="city">Город:</label>

                    <input value={newOrder.DiliveryCity} style={{ 'text-align': 'center' }} className={style.input} name="pets" id="city" placeholder="Город" type="text"
                      onChange={(event) => {
                        setNewOrder({ ...newOrder, DiliveryCity: event.target.value });
                      }}
                    />

                    {error.DiliveryCity && (
                      <p style={{ color: "red" }}>{error.DiliveryCity}</p>
                    )} </div>


                  <div className={style.adressInput}>
                    <label for="address">Адрес:</label>

                    <input value={newOrder.DiliveryAdress} style={{ 'text-align': 'center' }} name="address" id="address" autoComplete="street-address" placeholder="Улица" type="text"
                      onChange={(event) => {
                        setNewOrder({ ...newOrder, DiliveryAdress: event.target.value });
                      }} />
                    {error.DiliveryAdress && (
                      <p style={{ color: "red" }}>{error.DiliveryAdress}</p>
                    )}</div>

                  {/* </div>
                      <div className={style.adress2}> */}
                  <div className={style.adressInput}>
                    <label for="house">Дом/Подьезд:</label>

                    <input value={newOrder.DiliveryHouse} style={{ 'text-align': 'center' }} name="house" id="house" autoComplete="street-address" placeholder="Дом/Подьезд" type="text"
                      onChange={(event) => {
                        setNewOrder({ ...newOrder, DiliveryHouse: event.target.value });
                      }} />
                    {error.DiliveryHouse && (
                      <p style={{ color: "red" }}>{error.DiliveryHouse}</p>
                    )}</div>
                  <div className={style.adressInput}>
                    <label for="flat">Квартира/Офис:</label>

                    <input value={newOrder.DiliveryFlat} style={{ 'text-align': 'center' }} name="house" id="flat" autoComplete="street-address" placeholder="Квартира/Офис" type="text"
                      onChange={(event) => {
                        setNewOrder({ ...newOrder, DiliveryFlat: event.target.value });
                      }} />
                    {error.DiliveryFlat && (
                      <p style={{ color: "red" }}>{error.DiliveryFlat}</p>
                    )}</div>
                  {/* </div> */}



                  {/* <input name="unit" placeholder="Unit number" type="text" /> */}
                  {/* <input name="state" placeholder="State" type="text" /> */}
                  {/* <input name="country" placeholder="Country" type="text" /> */}
                  {/* <input name="postcode" placeholder="Postcode" type="text" /> */}
                {/* </form> */} 
                 <form>
                  <label for="city">Выберите адрес:</label>

                  <select name="pets" id="city"
                    onChange={(event) => {
                      setNewOrder({ ...newOrder, adress: event.target.value });
                    }}

                  >
                                        <option value="">--Выберите Адрес--</option>

                    {opt.user.adress.map((pr,index)=>{
                    const test = JSON.parse(pr)
                    return(
                    <option value={test}>Город {test.city}, ул.{test.city}, д{test.house}, оф.{test.flat}</option>

                    )
                  })}

                  </select>
                  {error.adress && (
                    <p style={{ color: "red" }}>{error.adress}</p>
                  )}
                </form> 
              </>
              )}
              <br></br>

              <Button text='Оформить Заказ' onClick={SendOrder} />
            </form>
          </div>
        </div>


      </>
    )}
    {newOrderID && (
      <>
        <Title text={'Заказ Создан!'} />
        <p style={{ textAlign: "center" }}>{`Спасибо что выбрали нас! Номер вашего заказа №`}<b>{newOrderID}</b>{`! В скором времени с васм свяжется менеджер для уточнения заказа!`}</p>
      </>
    )}
  </>
  )

}
export default OptOrder