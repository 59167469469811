import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewOrders.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../context/qtdContext";
import $api from "../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../../Modal/Modal';
import Button from '../../../../../Button/Button';
import { BACKURL } from '../../../../../../conf.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    // LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
registerLocale('ru', ru)

const UsersOrdersStats = () => {
    // const [startDate1, setStartDate] = useState(new Date());
    function sortByM(arr) {
        arr.sort((a, b) => a.totalMoney > b.totalMoney ? 1 : -1);
    }
    function sortByQ(arr) {
        arr.sort((a, b) => a.totalQty > b.totalQty ? 1 : -1);
    }
    ChartJS.register(
        CategoryScale,
        LinearScale,
        LineElement,
        BarElement,
        ArcElement,
        PointElement,
        Title,
        Tooltip,
        Legend
    );
    const { users, stat, servOrders, setStat } = useContext(QtdContext);
    const [startDate, setStartDate] = useState('2024-04-01');
    const [endDate, setEndDate] = useState('2024-04-30');
    // const startDate = ('2024-04-01');
    // const endDate = ('2024-04-30');
    useEffect(async () => {
        const data = await $api.post(`${BACKURL}/api/utbteam/getstat`, {
            startDate: startDate, endDate: endDate
        })
        setStat(data.data)
    }, [])

    const [days, setDays] = useState(14)

    const gotIt = servOrders.filter((order) => {
        const orderDate = new Date(order.createdAt);
        return order.status === 'Получен!' && orderDate >= new Date(startDate).setHours(0, 0, 0, 0) && orderDate <= new Date(endDate).setHours(23, 59, 59, 999);
    });
    let GotitStat = {
        total: {
            totalQty: 0,
            totalMoney: 0
        },
        products: [

        ],
        kategory: {
            cuns: {
                totalQty: 0,
                totalMoney: 0
            },
            lacomstva: {
                totalQty: 0,
                totalMoney: 0
            },
            racions: {
                totalQty: 0,
                totalMoney: 0
            },
            zamorozka: {
                totalQty: 0,
                totalMoney: 0
            },

            nocat: {
                totalQty: 0,
                totalMoney: 0
            },
        }
    }
    // console.log(gotIt)

    // useEffect(() => {

    for (let i = 0; i < gotIt.length; i++) {
        // console.log(gotIt[i].order)
        let thisCurt = gotIt[i].order
        for (let z = 0; z < thisCurt.length; z++) {
            let thisCurtParse = JSON.parse(thisCurt[z])
            GotitStat.total.totalQty = Number(GotitStat.total.totalQty) + Number(thisCurtParse.quantidade)
            GotitStat.total.totalMoney = Number(thisCurtParse.quantidade) * Number(thisCurtParse.price) + Number(GotitStat.total.totalMoney)
            if (!thisCurtParse.kategory) {
                GotitStat.kategory.nocat.totalQty = Number(GotitStat.kategory.nocat.totalQty) + Number(thisCurtParse.quantidade)
                GotitStat.kategory.nocat.totalMoney = Number(thisCurtParse.quantidade) * Number(thisCurtParse.price) + Number(GotitStat.kategory.nocat.totalMoney)

            }
            else if (thisCurtParse.kategory.includes('рацион')) {
                GotitStat.kategory.racions.totalQty = Number(GotitStat.kategory.racions.totalQty) + Number(thisCurtParse.quantidade)
                GotitStat.kategory.racions.totalMoney = Number(thisCurtParse.quantidade) * Number(thisCurtParse.price) + Number(GotitStat.kategory.racions.totalMoney)
            } else
                if (thisCurtParse.kategory.includes('консервы')) {
                    GotitStat.kategory.cuns.totalQty = Number(GotitStat.kategory.cuns.totalQty) + Number(thisCurtParse.quantidade)
                    GotitStat.kategory.cuns.totalMoney = Number(thisCurtParse.quantidade) * Number(thisCurtParse.price) + Number(GotitStat.kategory.cuns.totalMoney)

                } else
                    if (thisCurtParse.kategory.includes('сушенная продукция')) {
                        GotitStat.kategory.lacomstva.totalQty = Number(GotitStat.kategory.lacomstva.totalQty) + Number(thisCurtParse.quantidade)
                        GotitStat.kategory.lacomstva.totalMoney = Number(thisCurtParse.quantidade) * Number(thisCurtParse.price) + Number(GotitStat.kategory.lacomstva.totalMoney)

                    } else
                        if (thisCurtParse.kategory.includes('отдельная мороженная продукция')) {
                            GotitStat.kategory.zamorozka.totalQty = Number(GotitStat.kategory.zamorozka.totalQty) + Number(thisCurtParse.quantidade)
                            GotitStat.kategory.zamorozka.totalMoney = Number(thisCurtParse.quantidade) * Number(thisCurtParse.price) + Number(GotitStat.kategory.zamorozka.totalMoney)

                        }
            let condidate = GotitStat.products.find((gs) => gs.name == thisCurtParse.name)
            if (!condidate) {
                GotitStat.products.push({
                    name: thisCurtParse.name,
                    totalQty: thisCurtParse.quantidade,
                    totalMoney: Number(thisCurtParse.quantidade) * Number(thisCurtParse.price),
                    kategory: thisCurtParse.kategory,
                })
            } else {
                let newArr = GotitStat.products.filter((gs) => gs.name != thisCurt[z].name)
                newArr.push({
                    name: thisCurtParse.name,
                    kategory: thisCurtParse.kategory,
                    totalQty: Number(thisCurtParse.quantidade) + Number(condidate.totalQty),
                    totalMoney: Number(thisCurtParse.quantidade) * Number(thisCurtParse.price) + Number(condidate.totalMoney)

                })
                GotitStat.products = newArr
            }
            //add prod if no 

        }
    }


    let q = (GotitStat.products)
    sortByQ(q)

    const labels = ["Товары"];

    const data11 = {
        labels,
        datasets: (q.map((clk) => ({

            label: clk.name,
            data: [clk.totalQty],
            backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
        }))

        )

    };
    let m = (GotitStat.products)
    sortByM(m)
    const data12 = {
        labels,
        datasets: (m.map((clk) => ({

            label: clk.name,
            data: [clk.totalMoney],
            backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
        }))

        )

    };

    // console.log(data11)

    // }, [])
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: `Количество проданных едениц (${GotitStat.total.totalQty}шт)`,
            },
        },
    };
    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: `Выручка по каждому товару (${GotitStat.total.totalMoney}руб)`,
            },
        },
    };

    const options3 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Продано едениц по категориям с ${startDate} до ${endDate}`,
            },
        },
    };
    const datapai = {
        labels: [`Консервы (${GotitStat.kategory.cuns.totalQty}шт)`, `Сушка (${GotitStat.kategory.lacomstva.totalQty}шт)`,
        `Рацион (${GotitStat.kategory.racions.totalQty}шт)`, `Свежатина (${GotitStat.kategory.zamorozka.totalQty}шт)`],
        datasets: [
            {
                label: `Продано едениц`,
                data: [GotitStat.kategory.cuns.totalQty,
                GotitStat.kategory.lacomstva.totalQty, GotitStat.kategory.racions.totalQty,
                GotitStat.kategory.zamorozka.totalQty],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const options4 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Выручка по категориям с ${startDate} до ${endDate}`,
            },
        },
    };
    const datapai4 = {
        labels: [`Консервы (${GotitStat.kategory.cuns.totalMoney.toFixed(2)}р)`, `Сушка (${GotitStat.kategory.lacomstva.totalMoney.toFixed(2)}р)`,
        `Рацион (${GotitStat.kategory.racions.totalMoney.toFixed(2)}р)`, `Свежатина (${GotitStat.kategory.zamorozka.totalMoney.toFixed(2)}р)`],
        datasets: [
            {
                label: 'Выручка',
                data: [GotitStat.kategory.cuns.totalMoney.toFixed(2),
                GotitStat.kategory.lacomstva.totalMoney.toFixed(2), GotitStat.kategory.racions.totalMoney.toFixed(2),
                GotitStat.kategory.zamorozka.totalMoney.toFixed(2)],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    // const options5 = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             position: 'top',
    //         },
    //         title: {
    //             display: true,
    //             text: 'Клиенты',
    //         },
    //     },
    // };
    const us = stat.users.line.map((data) => data.allusers)
    const usNew = stat.users.line.map((data) => data.newusers)
    // const dataLine = {
    //     labels: stat.users.line.map((data) => data.date).slice(stat.users.line.length - days),
    //     datasets: [
    //         {
    //             label: 'Клиенты',
    //             data: us.slice(us.length - days),
    //             borderColor: 'rgb(255, 99, 132)',
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         }
    //     ],
    // };

    const os = stat.Orders.line.map((data) => data.allorders)
    const osNew = stat.Orders.line.map((data) => data.neworders)
    const avs = stat.Orders.line.map((data) => data.sumtotalamount)
    const vs = stat.Orders.line.map((data) => data.totalamount)

    const options6 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Клиенты (${us[us.length - 1]})/Заказы (${os[os.length - 1]})`,
            },
        },
    };
    const dataLine2 = {
        labels: stat.users.line.map((data) => data.date),
        // .slice(stat.users.line.length - days),
        datasets: [

            {
                label: 'Заказы',
                data: os,
                // .slice(os.length - days),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Клиенты',
                data: us,
                // .slice(us.length - days),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    //
    // const optionsCart = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             display: true
    //         },
    //         title: {
    //             display: true,
    //             text: 'Cart',
    //         },
    //     },
    // };

    // const cart = stat.Orders.line.map((data) => data.cart)


    // const cartName = cart.map((data) => data.name)
    // const cartQty = cart.map((data) => data.qty)
    // let ds = []
    // let allds = []

    // for (let i = 0; i < stat.Orders.line.length; i++) {

    //     for (let i2 = 0; i2<stat.Orders.line[i].cart.length; i2++) {
    //         console.log(stat.Orders.line[i].cart[i2])
    //         if (stat.Orders.line[i].cart[i2]) {
    //         ds.push({
    //             label: stat.Orders.line[i].cart[i2].name,
    //             data: [stat.Orders.line[i].cart[i2].qty],
    //             borderColor: 'rgb(53, 162, 235)',
    //             backgroundColor: cart[i].map((data) => '#' + Math.floor(Math.random() * 16777215).toString(16)).slice(cart.length - days),
    //         })
    //         }


    //     }
    //     // if()
    //     allds.push(ds)
    //     ds = []
    // }
    // console.log(allds)

    // const dataLineCart = {
    //     labels: stat.Orders.line.map((data) => data.date).slice(cart.length - days),
    //     datasets: allds.slice(cart.length - days),
    //     // cart.map((car) => ({
    //     //     label: cartName.slice(cart.length - days),
    //     //     data: cartQty.slice(cart.length - days),
    //     //     borderColor: 'rgb(53, 162, 235)',
    //     //     backgroundColor: cart.map((data) => '#' + Math.floor(Math.random() * 16777215).toString(16)).slice(cart.length - days),
    //     // }))
    // }

    // [
    //     {
    //         label: cartName.slice(cart.length - days),
    //         data: cartQty.slice(cart.length - days),
    //         borderColor: 'rgb(53, 162, 235)',
    //         backgroundColor: cart.map((data) => '#' + Math.floor(Math.random() * 16777215).toString(16)).slice(cart.length - days),
    //     }
    // ]
    // };
    const handleDateChange = async dates => {
        const [start, end] = dates;

        const data = await $api.post(`${BACKURL}/api/utbteam/getstat`, {
            startDate: start, endDate: end
        })
        setStat(data.data)
        setStartDate(start);
        setEndDate(end);
        // setdatepicker(false)
    };
    const optionsUsers = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Клиенты (${us[us.length - 1]})`,
            },
        },
    };
    const dataLineUsers = {
        labels: stat.users.line.map((data) => data.date),
        // .slice(stat.users.line.length - days),
        datasets: [


            {
                label: 'Клиенты',
                data: usNew,
                // .slice(usNew.length - days),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    const optionsOrders = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Заказы (${os[os.length - 1]})`,
            },
        },
    };
    const dataLineOrders = {
        labels: stat.users.line.map((data) => data.date),
        // .slice(stat.users.line.length - days),
        datasets: [


            {
                label: 'Заказы',
                data: osNew,
                // .slice(osNew.length - days),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ],
    };
    const optionsTA = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Суточная выручка',
            },
        },
    };
    const optionsATA = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Выручка',
            },
        },
    };
    const dataLineTA = {
        labels: stat.users.line.map((data) => data.date),
        datasets: [

            {
                label: 'Выручка',
                data: vs,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },

        ],
    };
    const dataLineATA = {
        labels: stat.users.line.map((data) => data.date),
        datasets: [

            {
                label: 'Выручка',
                data: avs,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },

        ],
    };
    return (<>

        <br />
        <table className={style.table}>
            <thead className={style.tableth}>

                <tr>

                    <td className={style.tabletd}>/</td>
                    <td className={style.tabletd}>Всего</td>
                    {/* <td className={style.tabletd}>Новых Сегодня</td> */}
                </tr>
            </thead>
            <tbody>

                <tr>

                    <td className={style.tabletd}>Пользователи</td>
                    <td className={style.tabletd}>{users.length}</td>
                    {/* <td className={style.tabletd}>{Number(us[us.length - 1]) - Number(us[us.length - 2])}</td> */}


                </tr>
                <tr>

                    <td className={style.tabletd}>Заказы</td>
                    <td className={style.tabletd}>{servOrders.length}</td>
                    {/* <td className={style.tabletd}>{Number(os[os.length - 1]) - Number(os[os.length - 2])}</td> */}



                </tr>


            </tbody>
        </table>
        <DatePicker
            selected={startDate}
            // onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            locale="ru"
            // selected={startDate}
            onChange={handleDateChange}
        />


        <div className={style.borders}>
            <Line options={options6} data={dataLine2} />

        </div>
        <div className={style.borders}>
            <Bar options={optionsTA} data={dataLineTA} />
        </div>
        <div className={style.borders}>
            <Line options={optionsATA} data={dataLineATA} />
        </div>
        <div className={style.pies}>
            {/* <div className={style.borders}> */}
            <div className={style.pie}>
                <Bar options={optionsUsers} data={dataLineUsers} />

                {/* </div > */}
            </div>
            {/* <div className={style.borders}> */}
            <div className={style.pie}>
                <Bar options={optionsOrders} data={dataLineOrders} />

                {/* </div> */}
            </div>


        </div>
        <div className={style.pies}>
            {/* <div className={style.borders}> */}
            <div className={style.pie}>
                <Pie options={options3} data={datapai} />

                {/* </div > */}
            </div>
            {/* <div className={style.borders}> */}
            <div className={style.pie}>
                <Pie options={options4} data={datapai4} />

                {/* </div> */}
            </div>


        </div>

    </>
    )
}
export default UsersOrdersStats