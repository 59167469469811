import React, { useContext, useEffect } from "react";
import LinksMenu from './LinksMenu'
import style from './Header.module.css'
// import style2 from './Header2.module.css'
import Logo from '../Logo/Logo'
import { Link } from 'react-router-dom'
import Infos from './Infos'
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation()
  // console.log(location.pathname, !(location.pathname == "/createorder"||location.pathname == "/prodinfo"))
  let ttt = style.container
  if(!(location.pathname == "/")){
    ttt = style.containerOrder
  }
  let test = true
  if(!(location.pathname == "/")){
    test = false
  }
  return (
    <div className={ttt}>

      {/* <nav className={style.nav_container}> */}
        {/* <Link to='/'>
          <div className={style.logo} style={{marginRight:'20px'}}>
            <Logo width={'200px'} />
          </div>
        </Link> */}
        {/* <LinksMenu /> */}
      {/* </nav> */}
      {test && (
        <div className={style.infos} style={{'margin-top':0}}>
          <Infos />
        </div>
      )}

    </div>
  )
}

export default Header