import React, { useContext, useEffect, useState } from "react";
import Produtos from '../../Produtos/Produtos'
import Title from '../../Title'
import style from './Sobre.module.css'
import MorozData from "../../Produtos/MorozData";

const Moroz = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])
    return (
    <div className={style.container_geral_prod}>
      <Title text="Свежемороженая продукция"/>
      {/* <div className={style.produtos_container}> */}
        <MorozData amount={112} columns={5} sizeContainer={1200}/>
      {/* </div> */}
    </div>
  );
};

export default Moroz;
