import React from 'react'
import ProdutosDry from '../../Produtos/ProdutosDry'
import Title from '../../Title'
import style from './Loja.module.css'
import { useEffect, useState, useContext } from "react";
import RacionsData from '../../Produtos/RacionsData';
import AmunitionData from '../../Produtos/AmunationData';

const Amunition = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])
    return (
    <div className={style.container_geral_prod}>
      <Title text="Амуниция"/>

      {/* <p className={style.p}>
        Наша продукция прошла лабораторные исследования, имеет ветеринарный  сертификат и декларацию соответствия.
      </p> */}
      {/* <div className={style.produtos_container}> */}
        <AmunitionData amount={112} columns={5} sizeContainer={1200}/>
      {/* </div> */}
    </div>
  )
}

export default Amunition