import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../../../Modal/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../../../conf.js'
const Zamorozka = () => {
    const { optUsers, admin, sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const lacom = sclad.filter((post) => post.kategory.includes('отдельная мороженная продукция'))

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    const [thisChange, setThisChange] = useState({})

    const edit = async (prod, q, p, o, about, name, recomendation, shcode, vipprice, optid) => {
        setThisChange({
            prod: prod,
            price: q,
            priceValue: '',
            optprice: o,
            optValue: '',
            qty: p,
            qtyValue: '',
            about: about,
            aboutValue: '',
            name: name,
            nameValue: '',
            recomendation: recomendation,
            recomendationValue: '',
            shcode: shcode,
            shcodeValue: '',
            vipprice: vipprice,
            vippriceOptUser: optid,
            vippriceValue: ''

        })
        setModalEdit(true)

    }
    const deleteProd = async (id) => {
        const DeletedId = await $api.get(`${BACKURL}/api/utbteam/deleteproduct/${id}`)
        const newprod = sclad.filter((post) => post.id != id)
        setSclad(newprod)
        alert('Товар удален! Эта функция будет ограничена!')
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить ордер?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };

    return (<>
        <div style={{ overflowX: 'auto' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>Код</td>
                        <td className={style.tabletd}>Наименоване</td>
                        <td className={style.tabletd}>Категория</td>
                        {admin.role == 'GOD' && (
<>
                            <td className={style.tabletd}>Описание</td>
                            <td className={style.tabletd}>Рекомендации</td>
                        </>)}

                        <td className={style.tabletd}>Остаток</td>
                        <td className={style.tabletd}>Брак</td>

                        <td className={style.tabletd}>Розница</td>
                        <td className={style.tabletd}>Опт без НДС</td>
                        {optUsers.length > 0 &&
                            (<>
                                {optUsers.map(ou => (
                                    <td className={style.tabletd}>{ou.name}</td>

                                ))}
                            </>)
                        }
                        {/* <td className={style.tabletd}>Опт с НДС</td> */}
                    </tr>
                </thead>
                <tbody>
                    {lacom.map(
                        (prod, index) => <>
                            <tr>
                                <td className={style.tabletd}>

                                    <div className={style.cell}><>
                                    {admin.role == 'GOD' && (
<>
                                            <FaEdit onClick={() => { edit(prod, false, false, false, false, false, false, true, false, '') }} className={style.icon} />
                                        </>)}
                                        {prod.shcode}
                                    </> </div>


                                </td>

                                <td className={style.tabletd}>
                                    <div className={style.cell}><>
                                    {admin.role == 'GOD' && (
<>
                                            <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                            <FaEdit onClick={() => { edit(prod, false, false, false, false, true, false, false, false, '') }} className={style.icon} />
                                        </>)}
                                        {prod.name}
                                    </> </div>


                                </td>
                                <td className={style.tabletd}>  {prod.kategory}</td>
                                {admin.role == 'GOD' && (
<>
                                    <td className={style.tabletd}>
                                        <FaEdit onClick={() => { edit(prod, false, false, false, true, false, false, false, false, '') }} className={style.icon} /></td>
                                    <td className={style.tabletd}>
                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, false, true, false, false, '') }} className={style.icon} />

                                    </td>

                                </>)}

                                <td className={style.tabletd}>
                                    <div className={style.cell}><>
                                    {admin.role == 'GOD' && (
                                            <FaEdit onClick={() => { edit(prod, false, true, false, false, false, false, false, '') }} className={style.icon} />
                                        )}
                                        {prod.scladqty}шт
                                    </> </div>



                                </td>
                                <td className={style.tabletd}>
                                   
 
                                   {prod.brakqty}шт
   
                                   </td>
                                <td className={style.tabletd}>
                                    <div className={style.cell}><>
                                    {admin.role == 'GOD' && (
<>
                                            <FaEdit onClick={() => { edit(prod, true, false, false, false, false, false, false, false, '') }} className={style.icon} />

                                        </>)}
                                        {prod.price}RUB
                                    </> </div>


                                </td>
                                <td className={style.tabletd}>
                                    <div className={style.cell}><>
                                    {admin.role == 'GOD' && (
<>
                                            <FaEdit onClick={() => { edit(prod, false, false, true, false, false, false, false, false, '') }} className={style.icon} />

                                        </>)}
                                        {prod.optprice}RUB
                                    </> </div>


                                </td>
                                {optUsers.length > 0 && prod.vipprice != undefined &&
                                    (<>
                                        {optUsers.map(ou => (<>
                                            {console.log((prod.vipprice.find(vp => vp.optuserid == ou.id)))}

                                            {(prod.vipprice.find(vp => vp.optuserid == ou.id)) && (
                                                <td className={style.tabletd}>

                                                    <div className={style.cell}><>
                                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, false, false, false, true, ou.id) }} className={style.icon} />
                                                        {(prod.vipprice.find(vp => vp.optuserid == ou.id)).vipprice}RUB

                                                    </> </div>

                                                </td>

                                            )}
                                            {/* {!(prod.vipprice.find(vp => vp.optuserid == ou.id)) && (
                                                <td className={style.tabletd}>0</td>

                                            )} */}

                                        </>))}
                                    </>)
                                }
                                {/* <td className={style.tabletd}>0 RUB</td> */}



                            </tr>

                        </>
                    )}
                </tbody>
            </table>
            {modalEdit && (
                <ModalEdit
                    thisChange={thisChange}
                    setThisChange={setThisChange}
                // prod = {prod}
                />

            )}
        </div>
    </>
    )
}
export default Zamorozka