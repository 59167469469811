
// import { deleteclick } from "../../../kotopesshop/app/services/clicks";
import $api from "../http/index.ts";


export default class ClicksService {
    static async addone(name) {
        return await $api.get(`/clicks/add/${name}`)
    }
    // static async noaddone(name) {
    //     return $api.get(`/clicks/noadd/${name}`)
    // }
    static async deleteclick(id) {
        return await $api.get(`/clicks/delete/${id}`)
    }
    static async getall() {
        const clicks = await $api.get(`/clicks/getall`)
        return clicks
    }
}