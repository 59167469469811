import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEdit from '../../../Modal/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../../../conf.js'
const Meat = () => {
    const { admin, sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const cuns = sclad.filter((post) => post.kategory.includes('Производство - Мясо'))

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])

    const [thisChange, setThisChange] = useState({})

    const edit = async (prod, q, p, o, about, name, recomendation, shcode) => {
        setThisChange({
            prod: prod,
            mnt: q,
            mntValue: '',
            qty: p,
            qtyValue: '',
            name: name,
            nameValue: '',
        })
        setModalEdit(true)

    }
    const deleteProd = async (id) => {
        const DeletedId = await $api.get(`${BACKURL}/api/utbteam/deleteproduct/${id}`)
        const newprod = sclad.filter((post) => post.id != id)
        setSclad(newprod)
        alert('Товар удален!')
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить товар?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };

    return (<>
        <div style={{ overflowX: 'auto' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>Наименоване</td>
                        <td className={style.tabletd}>Категория</td>
                      

                        <td className={style.tabletd}>Остаток</td>
                        <td className={style.tabletd}>Ед</td>
                      
                    </tr>
                </thead>
                <tbody>
                    {cuns.map(
                        (prod, index) => <>
                            <tr>
                              

                                <td className={style.tabletd}>
                                {admin.role == 'GOD' && (<>
                                        <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, true, false, false) }} className={style.icon} />
                                    </>)}
                                    {prod.name} </td>
                                <td className={style.tabletd}>  {prod.kategory}</td>
     

                                <td className={style.tabletd}>
                                {admin.role == 'GOD' && (
                                        <FaEdit onClick={() => { edit(prod, false, true, false, false, false, false) }} className={style.icon} />
                                    )}
                                    {prod.scladqty}</td>
                                <td className={style.tabletd}>
                                {admin.role == 'GOD' && (<>
                                        <FaEdit onClick={() => { edit(prod, true, false, false, false, false, false, false) }} className={style.icon} />

                                    </>)}
                                    {prod.mnt}</td>
                              
                            </tr>

                        </>
                    )}
                </tbody>
            </table>
            {modalEdit && (
                <ModalEdit
                    thisChange={thisChange}
                    setThisChange={setThisChange}
                // prod = {prod}
                />

            )}
        </div>
    </>
    )
}
export default Meat