import React from 'react'
// import Button from '../../../../Button/Button'
import style from './NewProdForm.module.css'
// import Title from "../../Title";
import Button from '../../../../../../Button/Button'
import $api from "../../../../../../../http/index.ts";

import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
// import $api from "../../../../../http/index.ts";
// // import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// // import 'react-tabs/style/react-tabs.css';
// import { FaEdit } from "react-icons/fa";
// import ModalEdit from '../Components/Modal/Modal';
import { BACKURL } from '../../../../../../../conf.js'
const AddSpec = () => {
    const { spec, setSpec, sclad, setSclad, modalEdit, setModalEdit } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const resourses = sclad.filter((post) => post.kategory.includes('Производство'))
    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // console.log(resourses)
    // }, [])

    const [newSpecName, setNewSpecName] = useState('')
    const [newSpecplanedqty, setNewSpecplanedQty] = useState('')
    const [newplanedqtymnt, setnewplanedqtymnt] = useState('')


    const [newSpec, setNewSpec] = useState([])
    const [newProd, setNewProd] = useState({
        name: "",
        qty: '',
        mnt: ''
    })
    const [err, setErr] = useState({})
    const [err2, setErr2] = useState({})
    const add = async (e) => {

        try {
            e.preventDefault();

            let t = {
                name: false,
                qty: false,
            }

            if (!newProd.name) {
                t.name = 'Наименование обязательно к заполнению!'
            }
            if (!newProd.qty) {
                t.qty = 'Категория обязательна к выбору!'
            }



            setErr(t)
            if (newProd.name &&
                newProd.qty &&

                !t.name &&
                !t.qty

            ) {
                const mnt = resourses.find((test) => test.name == newProd.name)
                console.log(mnt)
                // setNewProd({ ...newProd, mnt: mnt.mnt });

                setNewSpec([...newSpec, {
                    name: newProd.name,
                    qty: newProd.qty,
                    mnt: mnt.mnt
                }])
                setNewProd({
                    name: "",
                    qty: '',
                    mnt: ''
                })


                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            // console.log(e)
        }

    }
    const create = async (e) => {
        try {
            e.preventDefault();

            let t = {
                nameSpec: false,
                usedResorses: false,
                newSpecplanedqty: false,
                newplanedqtymnt: false,
            }

            if (newSpec.length <= 0) {
                t.nameSpec = 'Добавьте ресурсы!'
            }
            if (!newSpecName) {
                t.usedResorses = 'обязательно!'
            }
            if (!newSpecplanedqty) {
                t.newSpecplanedqty = 'обязательно!'
            }
            if (!newplanedqtymnt) {
                t.newplanedqtymnt = 'обязательно!'
            }




            setErr2(t)
            if (newSpec.length > 0 &&
                newSpecName &&
                newSpecplanedqty &&
                newplanedqtymnt &&
                !t.newplanedqtymnt &&
                !t.usedResorses &&
                !t.nameSpec &&
                !t.newSpecplanedqty

            ) {



                const newProd5 = await $api.post(`${BACKURL}/api/spec/createnew/`,
                    { newSpecName, newSpec, newSpecplanedqty, newplanedqtymnt }
                )
                setSpec(newProd5.data)
                setNewProd({})
                setNewSpec([])
                setNewSpecName('')
                alert('Готово!')
            }

            // console.log(newProd)
        } catch (e) {
            // console.log(e)
        }

    }

    return (<>
        <br />

        <table className={style.table}>
            <thead className={style.tableth}>

                <tr>
                    <td className={style.tabletd}>Наименоване</td>


                    <td className={style.tabletd}>Кол-во</td>
                    <td className={style.tabletd}>Ед</td>

                </tr>
            </thead>
            <tbody>
                {newSpec.map(
                    (prod, index) => <>
                        <tr>


                            <td className={style.tabletd}>
                                {/* {admin.user == 'ieresko' && (<>
                                        <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, true, false, false) }} className={style.icon} />
                                    </>)} */}
                                {prod.name} </td>
                            <td className={style.tabletd}>  {prod.qty}</td>
                            <td className={style.tabletd}>  {prod.mnt}</td>




                        </tr>

                    </>
                )}
            </tbody>
        </table>

        <div className={style.container_form}>
            <form className={style.form}>

                <label for="pet-select">Выберите название спецификации:</label>

                <input value={newSpecName} type="text" id="qty" name="qty" onChange={(event) => {
                    setNewSpecName(event.target.value);
                }} />

                {err2.nameSpec && (
                    <p style={{ color: "red" }}>{err2.nameSpec}</p>
                )}
                <label for="pet-select">Выберите расчетное количество продукции:</label>

                <input value={newSpecplanedqty} type="number" id="qty" name="qty" onChange={(event) => {
                    setNewSpecplanedQty(event.target.value);
                }} />

                {err2.newSpecplanedqty && (
                    <p style={{ color: "red" }}>{err2.newSpecplanedqty}</p>
                )}
                <br></br>
                <label for="pet-select">Выберите ед:</label>

                <select value={newplanedqtymnt} name="planedqtymnt" id="pet-select" onChange={(event) => {
                    setnewplanedqtymnt(event.target.value);
                }}>
                    <option value="">--Выберите ед--</option>
                    <option value="шт">шт</option>
                    <option value="кг">кг</option>


                </select>


                {err2.planedqtymnt && (
                    <p style={{ color: "red" }}>{err2.planedqtymnt}</p>
                )}
                <br></br>



                <div className={style.border}>
                    <label for="pet-select">Выберите ресурс:</label>
                    <select value={newProd.name} name="pets" id="pet-select" onChange={(event) => {
                        setNewProd({ ...newProd, name: event.target.value });
                    }}>
                        <option value="">--Выберите ресурс--</option>

                        {resourses.map((ress, index) =>
                            <option value={ress.name}>{ress.name}(На складе:{ress.scladqty}{ress.mnt})</option>

                        )}

                    </select>


                    {err.name && (
                        <p style={{ color: "red" }}>{err.name}</p>
                    )}
                    <br></br>


                    <label for="qty">Введите Количество:</label>
                    <input value={newProd.qty} type="number" id="qty" name="qty" onChange={(event) => {
                        setNewProd({ ...newProd, qty: event.target.value });
                    }} />
                    {err.qty && (
                        <p style={{ color: "red" }}>{err.qty}</p>
                    )}
                    <br></br>

                    <br></br>
                    <Button text='Добавить в рецепт!' onClick={add} />
                </div>
                <Button text='Создать!' onClick={create} />
                {err2.usedResorses && (
                    <p style={{ color: "red" }}>{err2.usedResorses}</p>
                )}
                <br></br>
            </form>
        </div>
    </>
    )
}
export default AddSpec